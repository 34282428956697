
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TimeSeriesIdentifierInputForm } from '@backoffice-frontend/time-series-identifier/domain';
import { TimeSeriesDynamicSelectionComponent } from '@backoffice-frontend/time-series-identifier/ui-time-series-dynamic-selection';
import { BaseFormulaTypeConfigComponent } from '@clean-code/feature/formula-builder/api-formula-builder';
import { TranslocoModule } from '@jsverse/transloco';

export enum FeatureTimeSeriesAnalysisIdentifierDatSourceConfigComponentTypes {
  ConfigType = 'TimeSeriesIdentifierFormulaDataSource',
}

@Component({
  standalone: true,
  selector:
    'time-series-identifier-feature-time-series-analysis-identifier-config',
  templateUrl:
    './feature-time-series-analysis-identifier-config.component.html',
  styleUrls: [
    './feature-time-series-analysis-identifier-config.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslocoModule,
    TimeSeriesDynamicSelectionComponent
],
})
export class FeatureTimeSeriesAnalysisIdentifierDatSourceConfigComponent extends BaseFormulaTypeConfigComponent {
  public override formGroup = new UntypedFormGroup({
    identifier: new TimeSeriesIdentifierInputForm(),
  });
}
