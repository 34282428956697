import { Route } from '@angular/router';
import { ForwardPriceCurvePermissions } from '@backoffice/shared/bo/util-permissions';
import { NgxPermissionsGuard } from 'ngx-permissions';

export const forwardPriceCurveRoutes: Route = {
  path: 'forward-price-curves',
  loadComponent: () =>
    import(
      './forward-price-curve-list/forward-price-curve-list.component'
    ).then((mod) => mod.ForwardPriceCurveListComponent),
  children: [
    {
      path: 'preview/:id',
      loadComponent: () =>
        import(
          './forward-price-curve-form/forward-price-curve-form.component'
        ).then((mod) => mod.ForwardPriceCurveFormComponent),

      canActivate: [NgxPermissionsGuard],
      data: {
        permissions: {
          only: ForwardPriceCurvePermissions.manage,
        },
      },
    },
  ],
};
