import { CommonModule, KeyValue } from '@angular/common';
import {
  Component,
  HostBinding,
  Injector,
  ViewEncapsulation,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { WidgetBaseFilterComponent } from '@backoffice-frontend/shared/bo/widgets/ui-widget-base-filter';
import { ID, indicate } from '@clean-code/shared/common';
import { BaseWidgetTypeContentComponent } from '@clean-code/shared/components/ui-dashboard';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import { TooltipModule } from '@clean-code/shared/components/ui-tooltip';
import { TranslocoModule } from '@jsverse/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { CustomersContractVolumeWidgetTypeConfig } from '../customer-contract-volume';
import { CustomersContractVolumeService } from '../services/customer-contract-volume.service';

@Component({
  standalone: true,
  selector: 'customer-contract-volume-content',
  templateUrl: './customer-contract-volume-content.component.html',
  styleUrls: ['./customer-contract-volume-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    RouterModule,

    TranslocoModule,
    WidgetBaseFilterComponent,
    ProgressBarComponent,
    TooltipModule,
  ],
})
export class CustomersContractVolumeContentComponent extends BaseWidgetTypeContentComponent<CustomersContractVolumeWidgetTypeConfig> {
  @HostBinding('class') class = 'customer-contract-volume-content';
  public data$: Observable<
    KeyValue<
      string,
      { volume: number; closed: number; companyId: ID; open: number }
    >[]
  >;
  public isLoading$ = new BehaviorSubject<boolean>(false);

  public constructor(injector: Injector) {
    super();

    const service = injector.get(CustomersContractVolumeService);

    this.data$ = this.configObservable.pipe(
      filter((value: CustomersContractVolumeWidgetTypeConfig) => !!value),
      switchMap((value: CustomersContractVolumeWidgetTypeConfig) =>
        service
          .dashboardCustomersContractVolume$(value.filter)
          .pipe(indicate(this.isLoading$)),
      ),
    );
  }
}
