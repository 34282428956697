<div
  class="dashboard-widget-preview"
  [ngStyle]="{ 'border-top': '5px solid ' + widget.color }"
>
  <div class="name">{{ widget.name }}</div>
  <div class="type">
    {{ 'DASHBOARDWIDGET.TYPES.' + widget.type | transloco }}
  </div>
  <div class="creator">{{ widget.creator.fullName }}</div>
  <div class="create-date">{{ widget.creator.date | date : 'mediumDate' }}</div>
</div>
