import { Injectable, inject } from '@angular/core';
import { AuthStore } from '@clean-code/shared/auth/util-auth';
import { anyElement } from '@clean-code/shared/common';

@Injectable({ providedIn: 'root' })
export class DashboardValidateService {
  public canAddWidgetOrChangeTenant(
    tenant: Array<string>,
    dashboardTenant: Array<string>,
  ): boolean {
    return anyElement<string>(dashboardTenant, tenant);
  }

  private authStore = inject(AuthStore);
  public hasPermission(permissions: string[]): boolean {
    // check if permissions is empty
    if (permissions.length === 0) {
      return true;
    }

    const userPermissions = this.authStore.permissions();

    // check if any of permissions is in userPermissions
    return anyElement<string>(permissions, userPermissions);
  }
}
