<div
  class="widget-type-content-component w-full h-full"
  class="{{ widget().type }}"
>
  @if (type().component) {
    <div class="header-template">
      @if (headerTemplate) {
        <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
      }
    </div>

    <ndc-dynamic
      #component
      class="w-full h-full"
      [ndcDynamicComponent]="type().component"
      [ndcDynamicInputs]="type().inputs"
    >
    </ndc-dynamic>
  } @else {
    <div class="flex flex-col text-center">
      {{ 'common.NOT_FOUND' | transloco }}
    </div>
  }
</div>
