import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormulaConfigProvider } from '@clean-code/feature/formula-builder/api-formula-builder';
import {
  BenchmarkComponentTypes,
  BenchmarkMethodeComponent,
  CustomDatSourceType,
  CustomDataSourceComponent,
  CustomMethodeComponent,
  CustomMethodeComponentTypes,
  MAMethodeComponent,
  MAMethodeComponentTypes,
  MarketToMarketComponentTypes,
  MarketToMarketMethodeComponent,
} from '@clean-code/feature/formula-builder/ui-methods';
import {
  FeatureTimeSeriesAnalysisIdentifierDatSourceConfigComponent,
  FeatureTimeSeriesAnalysisIdentifierDatSourceConfigComponentTypes,
} from '@clean-code/time-series-identifier/feature-feature-time-series-analysis-identifier-config';

@NgModule({
  imports: [CommonModule],
})
export class FormulaModule {
  constructor(configProvider: FormulaConfigProvider) {
    configProvider.add(
      CustomDatSourceType.ConfigType,
      CustomDataSourceComponent
    );

    configProvider.add(
      FeatureTimeSeriesAnalysisIdentifierDatSourceConfigComponentTypes.ConfigType,
      FeatureTimeSeriesAnalysisIdentifierDatSourceConfigComponent
    );

    configProvider.addMethod(
      CustomMethodeComponentTypes.MethodeType,
      CustomMethodeComponent
    );
    configProvider.addMethod(
      MAMethodeComponentTypes.MethodeType,
      MAMethodeComponent
    );

    configProvider.addMethod(
      BenchmarkComponentTypes.MethodeType,
      BenchmarkMethodeComponent
    );

    configProvider.addMethod(
      MarketToMarketComponentTypes.MethodeType,
      MarketToMarketMethodeComponent
    );
  }
}
