import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BuySignalNotificationModule } from '@backoffice-frontend/limit-report/notification/ui-buysignalnotification';
import { NotificationCenterComponent } from '@clean-code/notifications/feature-notification-center';
import { UserLimitUiViolationNotificationModule } from '@clean-code/user-limit/ui-violation-notification';

@NgModule({
  imports: [
    CommonModule,
    NotificationCenterComponent,
    BuySignalNotificationModule,

    //TODO: move
    UserLimitUiViolationNotificationModule,
  ],
  exports: [NotificationCenterComponent],
})
export class BoNotificationsModule {}
