import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'germanDateFormatCustom',
})
export class GermanDateFormatCustomPipe implements PipeTransform {
  transform(value: string): string {
    const wrongDateFormatRegex = /(\d{1,2}[/]\d{1,2}[/]\d{4})/;
    let wrongDateFormat: Date;
    let correctDateFormat: string;

    const wrongTimeFormatRegex = /(\d{2}[:]\d{2}[:]\d{2})/;
    let correctTimeFormat: string;

    if (value) {
      const wrongDateMatch = value.match(wrongDateFormatRegex);
      const wrongTimeMatch = value.match(wrongTimeFormatRegex);

      if (wrongDateMatch) {
        wrongDateFormat = new Date(wrongDateMatch[0]);
        correctDateFormat = wrongDateFormat.toLocaleDateString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });

        value = value.replace(wrongDateFormatRegex, correctDateFormat);
      }

      if (wrongTimeMatch) {
        const [hours, minutes] = wrongTimeMatch[0].split(':');
        correctTimeFormat = `${hours}:${minutes}`;
        value = value.replace(wrongTimeFormatRegex, correctTimeFormat);
      }
    }
    return value;
  }
}
