import { KeyValue } from '@angular/common';
import { TimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import { TimeSeries } from '@backoffice-frontend/time-series-identifier/api';
import {
  ChartTimeSeries,
  TimeSeriesFacade,
  TimeSeriesIdentifierInput,
  TimeSeriesPoint,
} from '@backoffice-frontend/time-series-identifier/domain';
import { findElementByLatestDate } from '@clean-code/shared/common';
import { LineChartModel } from '@clean-code/shared/components/ui-devextreme-charts';
import { TitleOptions } from 'chart.js';
import { map } from 'rxjs/operators';

export function chartData$(
  timeSeriesFacade: TimeSeriesFacade,
  identifiers: TimeSeriesIdentifierInput[],
  timeFrame: TimeFrame
) {
  return timeSeriesFacade.getTimeSeriesChartData$(identifiers, timeFrame).pipe(
    map((values: ChartTimeSeries[]) => {
      const array = new Array<any>();
      const errors = new Array<KeyValue<string, string>>();
      let info: string;
      let infoDate: Date;
      let yAxisTitle: TitleOptions;

      //only do this if there is a single line to show
      if (values.length === 1) {
        if (values[0].values.length == 0) {
          return {
            array,
            yAxisTitle,
            errors,
            infoDate,
            info,
          };
        }

        const max = findElementByLatestDate<TimeSeriesPoint>(
          values[0].values,
          'tradeDate'
        );

        if (max) {
          info = max.value + ' ' + values[0].unit;
          infoDate = max.tradeDate;
        }
      }

      values.forEach((value: ChartTimeSeries) => {
        if (!yAxisTitle) {
          yAxisTitle = <TitleOptions>{
            display: true,
            text: value.unit,
          };
        }

        const data = {
          label: value.frontOfficeName,
          data: value.values.map((val: TimeSeries) => ({
            date: new Date(val.tradeDate),
            value: val.value,
          })),
          yAxisTitle: value.unit,
        } as LineChartModel;

        if (value.error) {
          data.error = {
            key: value.frontOfficeName,
            value: value.error.message,
          } as KeyValue<string, string>;
        }

        if (value.error) {
          errors.push({
            key: value.frontOfficeName,
            value: value.error.message,
          } as KeyValue<string, string>);
        }

        array.push(data);
      });

      return {
        array,
        yAxisTitle,
        errors,
        infoDate,
        info,
      };
    })
  );
}
