<div
  class="dashboard-panel-preview"
  [ngStyle]="{ 'border-top': '5px solid ' + dashboard.color }"
>
  <div class="name">{{ dashboard.name.localizations[0].value }}</div>
  <div class="creator">{{ dashboard.creator.fullName }}</div>
  <div class="create-date">
    {{ dashboard.creator.date | date : 'mediumDate' }}
  </div>
</div>
