{
  "primary-color": "#82c828",
  "secondary-color": "rgba(242, 242,242, 1)",
  "accent-color": "#ff0000",
  "chart-color-1": "#82c828",
  "chart-color-2": "#4e7818",
  "chart-color-3": "#696969",
  "chart-color-4": "#ff6900",
  "chart-color-5": "#e61e26",
  "chart-color-6": "#FF1493",
  "chart-color-7": "#25a0da"
}
