import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationItem } from '../models/navigation-item';

@Injectable()
export class SideNavService {
  public openMenu$ = new BehaviorSubject<boolean>(false);
  public mobileMenuToggled$ = new BehaviorSubject<boolean>(false);
  public navigationItems$ = new BehaviorSubject<NavigationItem[]>([]);

  public toggleUserMenu() {
    this.openMenu$.next(!this.openMenu$.value);
    this.mobileMenuToggled$.next(this.openMenu$.value);
  }

  public addNavigation(items: NavigationItem[]) {
    this.navigationItems$.next(items);
  }
}
