@let conf = config();
<div [formGroup]="form" [patchFormGroupWithEmitValues]="conf">
  <div formGroupName="settings" class="flex flex-col">
    <div class="flex flex-row space-x-1 items-center">
      <mat-form-field>
        <mat-label>{{
          'time-series-identifier.TIME_FRAME' | transloco
        }}</mat-label>
        <mat-select formControlName="timeFrame">
          <mat-option [value]="null" selected>{{
            'common.NO_SELECTION' | transloco
          }}</mat-option>
          @for (item of timeFrames$ | async; track item) {
            <mat-option value="{{ item.name }}">{{
              'time-span-toggle.' + item.name | transloco
            }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          formControlName="startDate"
          [matDatepicker]="picker"
          [placeholder]="'time-series-identifier.INDEX_START_DATE' | transloco"
        />

        <mat-datepicker-toggle matSuffix (click)="clearStartDate($event)">
          <fa-icon matDatepickerToggleIcon [icon]="faTimes"></fa-icon>
        </mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{
          'correlation-chart-widget-settings.BASE_TYPE' | transloco
        }}</mat-label>
        <mat-select formControlName="type">
          <mat-option [value]="null" selected>{{
            'common.NO_SELECTION' | transloco
          }}</mat-option>
          @for (item of baseTypes$ | async; track item) {
            <mat-option value="{{ item.name }}">{{
              'correlation-chart-widget-settings.' + item.name | transloco
            }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  @if (form.hasError('atLeastOne')) {
    <div style="color: red">
      {{ 'ui-spread-widget.TIME_FRAME_NOT_VALID' | transloco }}
    </div>
  }

  <div class="flex flex-col">
    <span class="label">{{
      'correlation-chart-widget-settings.BASE' | transloco
    }}</span>
    <time-series-dynamic-selection
      class="w-full"
      formGroupName="identifier"
    ></time-series-dynamic-selection>
  </div>

  <div class="flex flex-col">
    <span class="label">{{
      'correlation-chart-widget-settings.COMPARISON' | transloco
    }}</span>
    <time-series-dynamic-selection-group
      [formGroup]="form"
      [dataSource]="conf?.dataSources"
    ></time-series-dynamic-selection-group>
    <!-- !TODO  refactor -->
    <!-- [calculationType]="calculationType$ | async" -->
  </div>
</div>
