import { Route } from '@angular/router';
import { ForecastResolver } from '@backoffice/forecast/domain';

export const forecastDetailsRoute: Route = {
  path: 'forecast-details/:id',
  loadComponent: () =>
    import('./forecast-details/forecast-details.component').then(
      (mod) => mod.ForecastDetailsComponent
    ),
  data: { translate: 'forecast' },
  resolve: { entity: ForecastResolver },
};
