import { Injectable, type OnDestroy, type Type, inject } from '@angular/core';
import {
  AuthInjectorToken,
  type IAuthService,
} from '@clean-code/shared/auth/util-auth';
import type { ID } from '@clean-code/shared/common';
import { ENV_TOKEN, type EnvConfig } from '@clean-code/shared/util-config';
import { HubConnectionState } from '@microsoft/signalr';
import { BehaviorSubject, type Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SignalRSettings } from '../application/signalr.settings';
import type { Message } from '../models/message';
import type { Notification } from '../models/notification';
import type { NotificationTypeDefinition } from '../models/notificationtypedefinition';
import { SignalRService } from './signalr.service';

@Injectable({ providedIn: 'root' })
export class NotificationService extends SignalRService implements OnDestroy {
  private notificationTypeDefinitions: NotificationTypeDefinition[] = [];

  private onNotificationReceiveBehaviorSubject$ = new BehaviorSubject<
    Message[]
  >([]);

  constructor() {
    const env = inject(ENV_TOKEN) as EnvConfig;
    const authService = inject<IAuthService>(AuthInjectorToken);

    super(
      new SignalRSettings({
        url: `${env.signalR.api}hubs/notification`,
        options: {
          accessTokenFactory: () => {
            return authService.getAccessToken();
          },
        },
        name: 'NotificationService',
      }),
    );

    this.onConnected$
      .pipe(takeUntil(this.closeSubject))
      .subscribe(this.onConnectionStateChanged.bind(this));
  }

  public get onNotificationReceive$(): Observable<Message[]> {
    return this.onNotificationReceiveBehaviorSubject$.asObservable();
  }

  //NotificationTypeRegistration
  public registerNotificationType(definition: NotificationTypeDefinition) {
    if (
      this.notificationTypeDefinitions.find((x) => x.name == definition.name)
    ) {
      throw new Error(
        `NotificationType for '${definition.name}' already exists!`,
      );
    }

    this.notificationTypeDefinitions.push(definition);
  }

  public getNotificationType(name: string): Type<Notification> {
    const type = this.notificationTypeDefinitions.find((x) => x.name == name);
    if (!type) {
      console.error(`Notification Item Type for ${name} not found!`);
      return undefined;
    }
    return type.componentType;
  }

  public setNotificationRead(id: ID): void {
    this.setNotificationsRead([id]);
  }

  public setNotificationsRead(id: ID[]): void {
    this.hubConnection.invoke('setread', id);
  }

  protected registerHubHandlers(): void {
    this.hubConnection.on('refresh', (data: Message[]) => {
      //string to date
      data.forEach((x) => (x.createDate = new Date(x.createDate)));
      this.onNotificationReceiveBehaviorSubject$.next(data);
    });

    this.hubConnection.on('add', (data: Message[]) => {
      //string to date
      data.forEach((x) => (x.createDate = new Date(x.createDate)));
      const notifications =
        this.onNotificationReceiveBehaviorSubject$.getValue();
      data.forEach((message) => notifications.push(message));
      this.onNotificationReceiveBehaviorSubject$.next(notifications);
    });

    this.hubConnection.on('setread', (data: ID[]) => {
      const notifications =
        this.onNotificationReceiveBehaviorSubject$.getValue();
      notifications.forEach((notification) => {
        if (data.includes(notification.id)) {
          notification.isRead = !notification.isRead;
        }
      });
      this.onNotificationReceiveBehaviorSubject$.next(notifications);
    });
  }

  protected removeHubHandlers(): void {
    this.hubConnection.off('refresh');
    this.hubConnection.off('add');
    this.hubConnection.off('setread');
  }

  private onConnectionStateChanged(connectionState: HubConnectionState) {
    if (connectionState === HubConnectionState.Connected) {
      this.hubConnection.invoke('refresh');
    } else {
      this.onNotificationReceiveBehaviorSubject$.next([]);
    }
  }
}
