import { Injectable } from '@angular/core';
import { WidgetBaseFilterModel } from '@backoffice-frontend/shared/bo/widgets/ui-widget-base-filter';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { IMarketHknPosition } from '../market-hkn-positions-content/market-hkn-positions-content.models';

@Injectable({
  providedIn: 'root',
})
export class HknPositionsService {
  constructor(private graphqlService: GraphqlService) {}

  public marketOverviewWidgetData$(
    value: WidgetBaseFilterModel
  ): Observable<IMarketHknPosition[]> {
    const query = `
          query($input: WidgetBaseFilterInput!) {
            dashboardOpenClosedHKN(input: $input)
            {
              key
              value
            }
          }`;

    return this.graphqlService.query<IMarketHknPosition[]>(query, {
      input: value,
    });
  }
}
