import { CommonModule } from '@angular/common';
import {
  Component,
  HostBinding,
  Injector,
  ViewEncapsulation,
} from '@angular/core';
import { IndividualTimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import {
  BaseChartWithMultipleTimeSeriesParams,
  ComparisonWidgetTypeConfig,
  DataSource,
} from '@backoffice-frontend/time-series-identifier/domain';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { BaseWidgetTypeContentComponent } from '@clean-code/shared/components/ui-dashboard';
import { ComparisonChartComponent } from '@clean-code/time-series-identifier/ui-comparison-chart';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faMagnifyingGlassChart } from '@fortawesome/pro-light-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChartDialogComponent } from '../chart-dialog/chart-dialog.component';

@Component({
  standalone: true,
  selector: 'chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, FontAwesomeModule, ComparisonChartComponent],
})
export class ChartWidgetComponent extends BaseWidgetTypeContentComponent<ComparisonWidgetTypeConfig> {
  @HostBinding('class') class = 'chart-widget';

  public isLoading$ = new BehaviorSubject<boolean>(false);
  public faMagnifyingGlassChart = faMagnifyingGlassChart;

  public data$ = this.configObservable.pipe(
    map((value: ComparisonWidgetTypeConfig) => {
      const timeFrame = value.settings.startDate
        ? ({
            start: value.settings.startDate,
            end: new Date(),
          } as IndividualTimeFrame)
        : value.settings.timeFrame;
      return {
        ...value,
        identifiers: value.dataSources.map((x: DataSource) => x.settings),
        timeFrame: timeFrame,
        showInPercentage: value.settings.showInPercentage,
        showTable: value.settings.showTable,
      } as BaseChartWithMultipleTimeSeriesParams;
    }),
  );
  private confirmService: ConfirmService;
  private deviceService: DeviceDetectorService;

  public constructor(injector: Injector) {
    super();
    this.confirmService = injector.get(ConfirmService);
    this.deviceService = injector.get(DeviceDetectorService);
  }

  expandChart() {
    this.confirmService.open(
      '',
      '',
      ChartDialogComponent,
      {
        widget: this.widget,
        config: this.config,
      },
      {
        maxWidth: '100vw',
        maxHeight: '100vh',
        width: this.deviceService.isMobile() ? '100vw' : '90vw',
        height: this.deviceService.isMobile() ? '100vh' : 'unset', //90vh
      },
    );
  }
}
