<div
  class="dashboard-reference-add mt-2"
  *ngVar="isLoading$ | async; let isLoading"
>
  @if (isLoading) {
    <cc-loading-indicator></cc-loading-indicator>
  }

  @if ((dashboards$ | async)?.length > 0) {
    <div class="grid grid-cols-3 gap-4">
      @for (dashboard of dashboards$ | async; track dashboard) {
        <dashboard-panel-preview
          [dashboard]="dashboard"
          (click)="toggleSelection(dashboard)"
          [ngClass]="{ selected: isSelected(dashboard) }"
        >
        </dashboard-panel-preview>
      }
    </div>
  } @else {
    <div class="dashboard-preview-content">
      <li class="empty">
        <a>
          <span class="name">{{ 'common.EMPTY' | transloco }}</span>
        </a>
      </li>
    </div>
  }

  <!-- @if (showFooter) {
    <div class="footer">
      <button mat-stroked-button (click)="cancel()">
        {{ 'common.CANCEL' | transloco }}
      </button>
    </div>
  } -->
</div>

<ng-template #empty>
  <div class="dashboard-preview-content">
    <li class="empty">
      <a>
        <span class="name">{{ 'common.EMPTY' | transloco }}</span>
      </a>
    </li>
  </div>
</ng-template>
