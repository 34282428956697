import { CommonModule } from '@angular/common';
import { Component, effect, HostBinding, inject, Input, OnDestroy, untracked } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { ID } from '@clean-code/shared/common';
import { ColorPickerComponent } from '@clean-code/shared/components/ui-color-picker';
import { IWidgetTypeProvider, WidgetTypeProvider } from '@clean-code/shared/components/ui-dashboard';
import { InputTranslationModule } from '@clean-code/shared/components/ui-translation';
import { VisibleToComponent } from '@clean-code/shared/components/ui-visible-to';
import { DashboardValidateService, DashboardWidgetFacade } from '@clean-code/shared/dashboard/widget/domain';
import { PatchFormGroupValuesDirective } from '@clean-code/shared/directives/ui-form';
import { TranslocoModule } from '@jsverse/transloco';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DashboardStore } from '@clean-code/shared/dashboard/dashboard/domain';

function allowedTenant(
  widgetTypeProvider: WidgetTypeProvider,
  dashboardValidateService: DashboardValidateService,
  dashboardTenats: string[]
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    let allowed = false; //= nameRe.test(control.value);
    const widgetType = widgetTypeProvider.getProvider(control.value);

    if (widgetType) {
      allowed = dashboardValidateService.canAddWidgetOrChangeTenant(
        widgetType?.tenant,
        dashboardTenats
      );
    }

    return !allowed
      ? {
        forbiddenWidget: {
          value: dashboardTenats
        }
      }
      : null;
  };
}

@Component({
  standalone: true,
  selector: 'dashboard-base-widget-base-config',
  templateUrl: './widget-base-edit.component.html',
  styleUrls: ['./widget-base-edit.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatSelectModule,

    TranslocoModule,

    InputTranslationModule,
    VisibleToComponent,
    ColorPickerComponent,
    PatchFormGroupValuesDirective
  ]
})
export class BaseWidgetBaseConfigComponent implements OnDestroy {
  @HostBinding('class') class = 'widget-edit-component';
  public dashboardStore = inject(DashboardStore);

  public isLoading$ = new BehaviorSubject<boolean>(false);
  public widget = this.dashboardWidgetFacade.widget;
  public form = new UntypedFormGroup({
    id: new FormControl<ID>(''),
    dashboardId: new FormControl<ID>(''),
    name: new FormControl<string>(null, Validators.required),
    type: new FormControl<string>({ value: '', disabled: false }, [
      Validators.required,
      allowedTenant(
        this.widgetTypeProvider,
        this.dashboardValidateService,
        this.dashboardStore.tenant()
      )
    ]),
    description: new FormControl<string>(null),
    color: new FormControl<string>('', Validators.required),
    shared: new FormControl<boolean>(false),
    visibleTo: new FormControl<string[]>(null)
  });
  public formSignal = toSignal(this.form.valueChanges);
  public typeSignal = toSignal(this.form.get('type').valueChanges);
  public type$ = this.dashboardWidgetFacade.getAvailableTypes$().pipe(
    map((types: string[]) => {
      return types.filter((type: string) => {
        const widgetType = this.widgetTypeProvider.getProvider(type);
        return (
          this.dashboardValidateService.canAddWidgetOrChangeTenant(
            widgetType.tenant,
            this.dashboardStore.tenant()
          ) &&
          this.dashboardValidateService.hasPermission(widgetType.permissions)
        );
      });
    })
  );

  // @Input()
  // public set widget(val: IDashboardWidgetDetailDto) {
  //   if (val) {
  //     if (!val.dashboardId) {
  //       throw new Error('dashboardId is not loaded');
  //     }

  //     this.form.get('dashboardId').setValue(val.dashboardId);
  //     this.inputWidget$.next(val);
  //   }
  // }

  // public get widget(): IDashboardWidgetLocalizationDetailDto {
  //   return this.form.value as IDashboardWidgetLocalizationDetailDto;
  // }

  // @Output()
  // public changeCallback = this.form.valueChanges.pipe(
  //   debounceTime(200),
  //   distinctUntilChanged(), //  execute only if a different value is emitted
  //   // TODO: with angular 14 check readonly function
  //   map((data) => {
  //     const temp: IDashboardWidgetDetailDto = Object.assign({}, data);

  //     //TODO: localization properties

  //     return temp;
  //   })
  // );
  @Input()
  widgetType: IWidgetTypeProvider;
  private closeSubject$ = new Subject<void>();

  // public availableWidgetTypesList = Array<ITupple<string, string>>();
  // public filtered$ = this.form.controls['type'].valueChanges.pipe(
  //   startWith(''),
  //   debounceTime(300),
  //   map((searchTerm) => this.filter(searchTerm))
  // );

  constructor(
    public dashboardWidgetFacade: DashboardWidgetFacade,
    public dialogRef: MatDialogRef<BaseWidgetBaseConfigComponent>,
    // private translationService: TranslocoService,
    private widgetTypeProvider: WidgetTypeProvider,
    private dashboardValidateService: DashboardValidateService
  ) {
    effect(() => {
      const type = this.typeSignal() as string;

      untracked(() => {
        this.dashboardWidgetFacade.updateWidgetType(type);
      });
    });
    // BaseWidgetBaseConfigComponent.translationService = translationService;
    // this.form.patchValue(this.dashboardWidgetFacade.widget());
    // effect(() => {
    //   const widget = this.formSignal() as IDashboardWidgetDetailDto;
    //   untracked(() => {
    //     if (widget) {
    //       this.dashboardWidgetFacade.updateWidget(widget);
    //     }
    //   });
    // });
    // this.inputWidget$
    //   .asObservable()
    //   .pipe(
    //     //fires twice, debounce does NOT help.
    //     //Seems to be an issue with the input "widget" and the multiple components it is used in
    //     //debounceTime(700),
    //     tap((value) => {
    //       if (value.id) {
    //         //load translation data
    //         this.dashboardWidgetFacade
    //           .getLocalizationsById$(value.id)
    //           .pipe(
    //             tap((localizations) => {
    //               const widget = ObjectHelper.cloneObject(
    //                 value
    //               ) as IDashboardWidgetDetailDto;
    //               widget.name = localizations.name;
    //               widget.description = localizations.description;
    //               this.widget$.next(widget);
    //             }),
    //             indicate(this.isLoading$),
    //             first()
    //           )
    //           .subscribe();
    //       } else {
    //         this.widget$.next(value);
    //       }
    //     }),
    //     takeUntil(this.closeSubject$)
    //   )
    //   .subscribe();
  }

  // public ngAfterViewInit(): void {
  //   this.dashboardWidgetFacade.getAvailableTypes$().subscribe((result) => {
  //     this.availableWidgetTypesList = result
  //       .map((t) => {
  //         return {
  //           key: t,
  //           value: this.translationService.translate(
  //             'DASHBOARDWIDGET.TYPES.' + t
  //           ),
  //         };
  //       })
  //       .sort((a, b) => a.value.localeCompare(b.value));

  //     // this.setTypeSelectedValue();
  //   });
  // }

  // private filter(searchTerm: any | string): ITupple<string, string>[] {
  //   let filterValue =
  //     searchTerm && searchTerm.value ? searchTerm.value : searchTerm;
  //   filterValue = (filterValue || '').toLowerCase();

  //   if (!filterValue) {
  //     return this.availableWidgetTypesList;
  //   }

  //   return this.availableWidgetTypesList.filter(
  //     (option) => option.value.toLowerCase().indexOf(filterValue) > -1
  //   );
  // }

  get type() {
    return this.form.get('type');
  }

  // public displayFn(widgetType: string) {
  //   return widgetType
  //     ? BaseWidgetBaseConfigComponent.translationService.translate(
  //         'DASHBOARDWIDGET.TYPES.' + widgetType
  //       )
  //     : '';
  // }

  public isSaveDisabled(): boolean {
    return !this.form.valid;
  }

  public sortComparator(a: MatOption, b: MatOption) {
    if (a.viewValue < b.viewValue) {
      return -1;
    }
    if (a.viewValue > b.viewValue) {
      return 1;
    }

    return 1;
  }

  ngOnDestroy(): void {
    this.closeSubject$.next();
    this.closeSubject$.complete();
  }
}
