<!-- <bo-drag-and-drop formControlName="datasourceSettings"></bo-drag-and-drop> -->

@let conf = config();
<ng-container [formGroup]="form" [patchFormGroupWithEmitValues]="conf">
  <div class="flex flex-row space-x-2" formGroupName="settings">
    <!-- Delta Type -->
    <div class="flex flex-col">
      <span class="calculation-label mb-1">{{
        'time-series-identifier.widgets.settings.DELTATYPE' | transloco
      }}</span>
      <mat-button-toggle-group
        formControlName="deltaType"
        name="deltaType"
        aria-label="Delta Type"
      >
        <mat-button-toggle value="DAY">{{
          'time-series-identifier.widgets.settings.DELTATYPE_DAY' | transloco
        }}</mat-button-toggle>
        <mat-button-toggle value="MONTH">{{
          'time-series-identifier.widgets.settings.DELTATYPE_MONTH' | transloco
        }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="flex flex-col">
      <!-- Hide closing price header -->
      <span class="calculation-label mb-1">{{
        'time-series-identifier.widgets.settings.HIDE_CLOSING_PRICE_HEADER'
          | transloco
      }}</span>
      <div class="flex h-full items-center justify-center">
        <mat-slide-toggle
          formControlName="hideClosingValueHeader"
          aria-label="Hide header"
        ></mat-slide-toggle>
      </div>
    </div>
  </div>
  <time-series-dynamic-selection-group
    [formGroup]="form"
    [dataSource]="conf?.dataSources"
  ></time-series-dynamic-selection-group>
</ng-container>
