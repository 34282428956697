import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'dateTimeCommaCustom',
})
export class DateTimeCommaCustomPipe implements PipeTransform {
  transform(value: string): string {
    const dateTimeRegex = /(\d{2}[.]\d{2}[.]\d{4}[ ]\d{2}[:]\d{2})/;
    let dateTime: string;

    if (value) {
      const dateTimeMatch = value.match(dateTimeRegex);
      if (dateTimeMatch) {
        dateTime = dateTimeMatch[0].replace(' ', ', ');
        value = value.replace(dateTimeRegex, dateTime);
      }
    }

    return value;
  }
}
