import { CommonModule, KeyValue } from '@angular/common';
import {
  Component,
  HostBinding,
  Injector,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { mapTimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import {
  DataSource,
  WidgetTypeConfig,
} from '@backoffice-frontend/time-series-identifier/api';
import { SpreadChartComponent } from '@clean-code/frontoffice/spread/ui-spread-chart';
import { indicate } from '@clean-code/shared/common';
import { BaseChartWidget } from '@clean-code/shared/components/ui-dashboard';
import { ProgressBarModule } from '@clean-code/shared/components/ui-progress-bar';
import { faMagnifyingGlassChart } from '@fortawesome/pro-light-svg-icons';
import { IndividualSpreadsChartFacade } from '@frontoffice/spread/domain';
import { TitleOptions } from 'chart.js';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
  standalone: true,
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, ProgressBarModule, SpreadChartComponent],
})
export class ChartWidgetComponent extends BaseChartWidget<WidgetTypeConfig> {
  @HostBinding('class') class = 'spread-chart-widget';
  @ViewChild(SpreadChartComponent)
  spreadChart?: SpreadChartComponent;

  public isLoading$ = new BehaviorSubject<boolean>(false);
  public errors = new Array<KeyValue<string, string>>();
  public info: string;
  public infoDate: Date;
  public faExpand = faMagnifyingGlassChart;

  public yAxisTitle$ = new BehaviorSubject<TitleOptions>(undefined);

  public data$ = this.configObservable.pipe(
    debounceTime(300),
    mapTimeFrame(),
    switchMap((config: WidgetTypeConfig) =>
      this.facade
        .withDynamicInput$(
          config.dataSources.map((x: DataSource) => x.settings.identifier),
          config.settings.timeFrame
        )
        .pipe(indicate(this.isLoading$))
    )
  );

  facade: IndividualSpreadsChartFacade;

  public constructor(injector: Injector) {
    super();

    this.facade = injector.get(IndividualSpreadsChartFacade);
  }

  updateChart(): void {
    this.spreadChart?.updateCharts();
  }

  // expandChart(widget: any, dataSource: DataSource) {
  //   this.confirmService.open(
  //     '',
  //     '',
  //     ChartDialogComponent,
  //     {
  //       widget: widget,
  //       timeFrame: this.config$.value.settings.timeFrame,
  //       dataSource: dataSource,
  //     },
  //     {
  //       minWidth: '90vw',
  //       minHeight: '90vh',
  //     }
  //   );
  // }
}
