
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  effect,
  inject,
  untracked,
} from '@angular/core';
import {
  IDashboardWidgetCorrelationsUpdateInput,
  IDashboardWidgetDetailDto,
  IWidgetPositionInfo,
} from '@clean-code/shared/components/ui-dashboard';
import { DashboardFacade } from '@clean-code/shared/dashboard/dashboard/domain';
import {
  CompactType,
  DisplayGrid,
  GridType,
  GridsterComponent,
  GridsterConfig,
  GridsterItem,
  GridsterItemComponentInterface,
  GridsterModule,
} from 'angular-gridster2';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { DashboardPanelWidgetComponent } from '../dashboard-panel-widget/dashboard-panel-widget.component';

@Component({
  standalone: true,
  selector: 'dashboard-dashboard-panel-widgets',
  templateUrl: './dashboard-panel-widgets.component.html',
  styleUrls: ['./dashboard-panel-widgets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [GridsterModule, DashboardPanelWidgetComponent],
})
export class DashboardPanelWidgetsComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'dashboard-dashboard-panel-widgets';

  @ViewChild('gridster', { static: false }) gridster: GridsterComponent;

  private _options: GridsterConfig;
  private closeSubject = new Subject<void>();
  private syncGridster$ = new Subject<GridsterItemComponentInterface>();
  counter = 0;
  @Input()
  public set options(value: GridsterConfig) {
    if (value) {
      Object.assign(this._options, value);
    }
  }

  public get options(): GridsterConfig {
    return this._options;
  }

  public dashboardFacade = inject(DashboardFacade);
  public widgets = this.dashboardFacade.widgets;

  constructor() {
    this._options = this.getDefaultOptions();
    effect(() => {
      const value = this.dashboardFacade.editMode();
      untracked(() => {
        this.options.draggable.enabled = value;
        this.options.resizable.enabled = value;
        this.changedOptions();

        if (!value) {
          const payload = this.syncPositions();

          if (payload) {
            this.dashboardFacade.updateAllCorrelations$(payload).subscribe();
          }
        }
      });
    });
  }

  ngOnInit() {
    if (this.options) {
      this.options?.api?.optionsChanged();
    }

    this.syncGridster$
      .pipe(
        debounceTime(400),
        tap((x: GridsterItemComponentInterface) => this.syncWidgetState(x)),
        takeUntil(this.closeSubject)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.closeSubject.next();
    this.closeSubject.complete();
  }

  public trackByFn(_index: number, widget: IDashboardWidgetDetailDto) {
    return widget.id;
  }

  private getDefaultOptions(): GridsterConfig {
    return {
      gridType: GridType.Fit,
      compactType: CompactType.None,
      // pushItems: true,
      displayGrid: DisplayGrid.OnDragAndResize,
      minCols: 50,
      minRows: 50,
      maxCols: 50,
      maxRows: 50,
      defaultItemCols: 5,
      defaultItemRows: 5,
      mobileFitContentHeight: true,
      itemChangeCallback: (
        _item: GridsterItem,
        itemComponent: GridsterItemComponentInterface
      ) => {
        this.syncGridster$.next(itemComponent);
      },
      draggable: {
        enabled: false,
        // delayStart: 400,
        // stop: (
        //   _item: GridsterItem,
        //   itemComponent: GridsterItemComponentInterface,
        //   _event: MouseEvent
        // ) => {
        //   // this.syncWidgetState(itemComponent);
        //   this.syncGridster$.next(itemComponent);
        // },
      },
      resizable: {
        enabled: false,
        // delayStart: 400,
        // stop: (
        //   _item: GridsterItem,
        //   itemComponent: GridsterItemComponentInterface,
        //   _event: MouseEvent
        // ) => {
        //   // this.syncWidgetState(itemComponent);
        //   // this.saveCorrelation(item, itemComponent, event);
        //   this.syncGridster$.next(itemComponent);
        // },
      },
    };
  }

  private syncWidgetState(item: GridsterItemComponentInterface) {
    const widget = {
      ...item.item,
      x: item.$item.x,
      y: item.$item.y,
      cols: item.$item.cols,
      rows: item.$item.rows,
    } as IDashboardWidgetDetailDto;

    this.dashboardFacade.updateWidgetPosition(widget.id, widget);
  }

  private syncPositions(): IDashboardWidgetCorrelationsUpdateInput {
    const dashboardId = this.dashboardFacade.active()?.id;
    if (!dashboardId) {
      return null;
    }

    if (!this.gridster) {
      return null;
    }

    const payload: IDashboardWidgetCorrelationsUpdateInput = {
      dashboardId: this.dashboardFacade.active().id,
      widgets: [] as IWidgetPositionInfo[],
    };

    this.gridster.grid.forEach((item) => {
      const widget = item.item as IDashboardWidgetDetailDto;
      // if (
      //   item.$item.x !== widget.x ||
      //   item.$item.y !== widget.y ||
      //   item.$item.cols !== widget.cols ||
      //   item.$item.rows !== widget.rows
      // ) {
      payload.widgets.push({
        id: widget.id,
        x: item.$item.x,
        y: item.$item.y,
        cols: item.$item.cols,
        rows: item.$item.rows,
      });
    });

    return payload;
  }

  private changedOptions(): void {
    if (this._options.api && this._options.api.optionsChanged) {
      this._options.api.optionsChanged();
    }
  }

  addCounter() {
    this.counter += 1;
  }
}
