// @NgModule({
//   imports: [
//     CommonModule,
//     RouterModule.forChild([
//       {
//         path: '',
//         pathMatch: 'full',
//         component: StandardizedLoadProfileComponent,
//         resolve: {
//           entity: StandardizedLoadProfileResolver,
//         },
//       },
//     ]),
//     TranslocoModule,

//     FontAwesomeModule,

//     StandardizedLoadProfileFormComponent,
//     StandardizedLoadProfileCheckComponent,
//     StandardizedLoadProfileEntriesComponent,
//     StandardizedLoadProfileUploadComponent,

//     ActionButtonModule,

//     PageTileContainerComponent,
//   ],
//   declarations: [StandardizedLoadProfileComponent],
// })
// export class StandardizedLoadProfileModule {}

export const standardizedLoadProfileRoute = {
  path: 'standardized-load-profile/:id',
  loadComponent: () =>
    import('./standardized-load-profile.component').then(
      (mod) => mod.StandardizedLoadProfileComponent
    ),
  data: {
    translate: 'standardized-load-profile',
  },
  // resolve: {
  //   entity: StandardizedLoadProfileResolver,
  // },
};
