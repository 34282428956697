<h2 mat-dialog-title>
  {{ 'table-widget.EDIT_SYMBOL' | transloco }}
</h2>
<div mat-dialog-content [formGroup]="formGroup" class="h-full">
  <div class="flex flex-col">
    !!TODO update angular v18
    <!-- [dataSource]="editableData?.additionalDataSources ?? []" -->

    <time-series-dynamic-selection-group
      caption="table-widget.CHART_COMPARE"
      [formGroup]="settings"
      [formCtrlName]="'additionalDataSources'"
    ></time-series-dynamic-selection-group>

    <div>
      {{ 'table-widget.CHART_SETTINGS_TITLE' | transloco }}
    </div>

    <!-- Summary Type -->
    <div class="flex flex-col pt-2">
      <span class="label mb-1">{{
        'table-widget.SUMMARYTYPE' | transloco
      }}</span>
      <div>
        <mat-button-toggle-group
          formControlName="summaryType"
          name="summaryType"
          aria-label="Summary Type"
          >
          <mat-button-toggle value="DEFAULT">{{
            'table-widget.SUMMARYTYPE_DEFAULT' | transloco
          }}</mat-button-toggle>
          <mat-button-toggle value="COMPACT">{{
            'table-widget.SUMMARYTYPE_COMPACT' | transloco
          }}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <mat-accordion class="pt-4">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Aroon</mat-panel-title>
        </mat-expansion-panel-header>
        <div formGroupName="aroon" class="flex flex-col">
          <mat-checkbox formControlName="enabled">{{
            'common.ENABLED' | transloco
          }}</mat-checkbox>
          <mat-form-field>
            <mat-label>{{ 'table-widget.PERIOD' | transloco }}</mat-label>
            <input type="number" matInput formControlName="period" />
          </mat-form-field>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Moving average</mat-panel-title>
        </mat-expansion-panel-header>
        <div formGroupName="sma" class="flex flex-col">
          <mat-checkbox formControlName="enabled">{{
            'common.ENABLED' | transloco
          }}</mat-checkbox>
          <mat-form-field>
            <mat-label>Moving averages</mat-label>
            <mat-chip-grid #chipList aria-label="Fruit selection">
              @for (period of periodeArray.value; track period) {
                <mat-chip-row
                  (removed)="remove(period)"
                  >
                  {{ period }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip-row>
              }
              <input
                type="number"
                placeholder="{{ 'table-widget.PERIOD' | transloco }}"
                #periodeInput
                [formControl]="periodeCtrl"
                [matChipInputFor]="chipList"
                [matChipInputAddOnBlur]="true"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"
                />
              </mat-chip-grid>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Bollinger Band </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="flex flex-col" formGroupName="bollinger">
            <mat-checkbox formControlName="enabled">{{
              'common.ENABLED' | transloco
            }}</mat-checkbox>
            <mat-form-field>
              <mat-label>{{ 'table-widget.PERIOD' | transloco }}</mat-label>
              <input type="number" matInput formControlName="period" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{
                'table-widget.STANDARD_DEVIATION' | transloco
              }}</mat-label>
              <input type="number" matInput formControlName="standardDeviation" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div mat-dialog-actions class="flex flex-row flex-nowrap items-center">
    <div class="flex-auto"></div>
    <button mat-button (click)="dialogRef.close()">
      {{ 'common.CANCEL' | transloco }}
    </button>
    <button
      mat-button
      color="primary"
      [disabled]="!formGroup.valid"
      (click)="setDataAndClose()"
      cdkFocusInitial
      >
      {{ 'common.SAVE' | transloco }}
    </button>
  </div>
