import { Injectable, inject } from '@angular/core';
import { ID, indicate } from '@clean-code/shared/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { DashboardStore } from '../+state/dashboard-signal.store';
import { DashboardDataService } from '../infrastructure/dashboard-data.service';
import { IDashboardListDto } from '../models/dashboard.models';

@Injectable({ providedIn: 'root' })
export class DashboardMenuFacade {
  isLoading$ = new BehaviorSubject<boolean>(false);

  private store = inject(DashboardStore);
  private service = inject(DashboardDataService);

  public assigned = this.store.entities;

  delete$(id: ID): Observable<boolean> {
    return this.service.delete$(id).pipe(indicate(this.isLoading$));
  }

  remove$(d: IDashboardListDto): Observable<boolean> {
    return this.service.remove$(d).pipe(indicate(this.isLoading$));
  }

  addDasshbord(d: IDashboardListDto) {
    this.store.add(d);
  }

  removeFromState(id: ID) {
    this.store.remove(id);
  }
}
