<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>

<ng-template #headerTemplate let-data="data">
  <div class="h-6 mr-3 flex justify-end flex-shrink-0" (click)="expandChart()">
    <fa-icon
      [icon]="faExpand"
      class="text-base cursor-pointer hover:text-primary-color"
    ></fa-icon>
  </div>
</ng-template>

<mat-tab-group>
  @for (item of data$ | async; track item.id; let i = $index) {
    <mat-tab label="{{ item.label }}">
      <ng-template matTabContent>
        <div class="flex w-full h-full">
          <cc-line-chart [data]="[item]" [legend]="legend"></cc-line-chart>
        </div>
      </ng-template>
    </mat-tab>
  }
</mat-tab-group>
