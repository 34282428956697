import { computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStore } from '@clean-code/shared/auth/util-auth';
import type { ID, IEntityPositionDto } from '@clean-code/shared/common';
import {
  type IDashboardWidgetDetailDto,
  WidgetTypeProvider,
} from '@clean-code/shared/components/ui-dashboard';
import { ENV_TOKEN } from '@clean-code/shared/util-config';
import { ToastService } from '@clean-code/shared/util/util-toast';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  type,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  type EntityId,
  SelectEntityId,
  addEntities,
  addEntity,
  removeAllEntities,
  removeEntity,
  setEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { debounceTime, pipe, switchMap, tap } from 'rxjs';
import { DashboardDataService } from '../infrastructure/dashboard-data.service';
import type {
  IDashboardDetailDto,
  IDashboardListDto,
  IDashboardPanelPreviewDto,
  IDashboardReferenceDto,
} from '../models/dashboard.models';

type DashboardState = {
  editMode: boolean;
  allowedEdit: boolean;
  active: IDashboardDetailDto;
  // widgets: IDashboardWidgetDetailDto[];
  isLoading: boolean;
  selectedWidget: {
    widget: IDashboardWidgetDetailDto;
  };

  editing: IDashboardListDto;
};

const initialState: DashboardState = {
  editMode: false,
  allowedEdit: false,
  active: null,
  isLoading: false,
  // widgets: [],
  selectedWidget: null,
  editing: null,
};

const widgetSelectId: SelectEntityId<IDashboardWidgetDetailDto> = (widget) =>
  widget.id as EntityId;

const dashboardSelectId: SelectEntityId<IDashboardListDto> = (dashboard) =>
  dashboard.id as EntityId;

export const DashboardStore = signalStore(
  { providedIn: 'root' },
  withEntities({
    entity: type<IDashboardListDto>(),
  }),
  withEntities({
    entity: type<IDashboardWidgetDetailDto>(),
    collection: 'widgets',
  }),
  withState(() => {
    const env = inject(ENV_TOKEN);
    initialState.allowedEdit = env.allowEditDashboard ?? false;
    return initialState;
  }),
  withComputed(({ active, widgetsEntities }) => {
    const authStore = inject(AuthStore);

    const widgetTypeProvider = inject(WidgetTypeProvider);
    return {
      hasUserPermission: computed(() => {
        return active().creator.id === authStore.id();
      }),

      mappedWidgets: computed(() => {
        return widgetsEntities().map((x) => {
          const provider = widgetTypeProvider.getProvider(x.type as string);
          const minItemRows = provider?.minItemRows ?? 0;
          const minItemCols = provider?.minItemCols ?? 0;

          return {
            ...x,
            cols: x.cols >= minItemCols ? x.cols : minItemCols,
            rows: x.rows >= minItemRows ? x.rows : minItemRows,
            minItemRows: minItemRows,
            minItemCols: minItemCols,
          } as IDashboardWidgetDetailDto;
        });
      }),
      tenant: computed(() => active().tenant),
    };
  }),
  withMethods((store) => {
    const service = inject(DashboardDataService);
    const router = inject(Router);
    const toastService = inject(ToastService);

    // const queryParam = injectParams('id');
    // const id = queryParam();

    return {
      getById: rxMethod<ID>(
        pipe(
          debounceTime(300),
          tap(() => patchState(store, { isLoading: true })),
          switchMap((id) =>
            service.getById$(id).pipe(
              tapResponse({
                next: (entity: IDashboardDetailDto) => {
                  patchState(store, { active: entity });
                  patchState(
                    store,
                    removeAllEntities({ collection: 'widgets' }),
                  );
                  patchState(
                    store,
                    addEntities(entity.widgets, {
                      collection: 'widgets',
                      selectId: widgetSelectId,
                    }),
                  );
                },
                error: console.error,
                finalize: () => patchState(store, { isLoading: false }),
              }),
            ),
          ),
        ),
      ),
      update: rxMethod<IDashboardDetailDto>(
        pipe(
          debounceTime(300),
          tap(() => patchState(store, { isLoading: true })),
          switchMap((entity) =>
            service.update$(entity).pipe(
              tapResponse({
                next: (active: IDashboardDetailDto) => {
                  if (store.active().id === active.id) {
                    patchState(store, { active });
                  }
                  toastService.showSuccess();
                },
                error: console.error,
                finalize: () => patchState(store, { isLoading: false }),
              }),
            ),
          ),
        ),
      ),
      getAssigned: rxMethod<void>(
        pipe(
          debounceTime(300),
          tap(() => patchState(store, { isLoading: true })),
          switchMap(() =>
            service.getAssigned$().pipe(
              tapResponse({
                next: (entities: IDashboardListDto[]) => {
                  patchState(
                    store,
                    addEntities(entities, { selectId: dashboardSelectId }),
                  );
                },
                error: console.error,
                finalize: () => patchState(store, { isLoading: false }),
              }),
            ),
          ),
        ),
      ),
      remove(id: ID) {
        patchState(store, removeEntity(id));
      },

      add: rxMethod<IDashboardListDto>(
        pipe(
          debounceTime(300),
          tap(() => patchState(store, { isLoading: true })),
          switchMap((entity) =>
            service.add$(entity).pipe(
              tapResponse({
                next: (id: ID) => {
                  entity.id = id;
                  patchState(
                    store,
                    setEntity(entity, { selectId: dashboardSelectId }),
                  );

                  router.navigate(['/dashboard', id]);
                },
                error: console.error,
                finalize: () => patchState(store, { isLoading: false }),
              }),
            ),
          ),
        ),
      ),
      addReference: rxMethod<IDashboardPanelPreviewDto>(
        pipe(
          debounceTime(300),
          tap(() => patchState(store, { isLoading: true })),
          switchMap((entity) =>
            service
              .reference$({
                id: entity.id,
              } as IDashboardReferenceDto)
              .pipe(
                tapResponse({
                  next: (active: IDashboardListDto) => {
                    patchState(
                      store,
                      addEntity(active, { selectId: dashboardSelectId }),
                    );
                    router.navigate(['/dashboard', active.id]);
                  },
                  error: console.error,
                  finalize: () => patchState(store, { isLoading: false }),
                }),
              ),
          ),
        ),
      ),
      async updateEditMode(editMode: boolean) {
        patchState(store, { editMode });
      },

      addWidget(widget: IDashboardWidgetDetailDto) {
        patchState(
          store,
          addEntity(widget, {
            collection: 'widgets',
            selectId: widgetSelectId,
          }),
        );
      },

      removeWidget(widget: IDashboardWidgetDetailDto) {
        patchState(
          store,
          removeEntity(widget.id, {
            collection: 'widgets',
          }),
        );
      },

      updateActive(active: IDashboardDetailDto) {
        patchState(store, { active });
        patchState(
          store,
          addEntities(active.widgets, {
            collection: 'widgets',
            selectId: widgetSelectId,
          }),
        );
      },

      async updateWidget(active: IDashboardWidgetDetailDto) {
        patchState(
          store,
          updateEntity(
            {
              id: active.id,
              changes: active,
            },
            {
              collection: 'widgets',
              selectId: widgetSelectId,
            },
          ),
        );
      },

      async updateWidgetPosition(id: ID, widget: IEntityPositionDto) {
        patchState(
          store,
          updateEntity(
            {
              id: id,
              changes: {
                x: widget.x,
                y: widget.y,
                cols: widget.cols,
                rows: widget.rows,
              },
            },
            {
              collection: 'widgets',
              selectId: widgetSelectId,
            },
          ),
        );
      },
    };
  }),
  withHooks({
    onInit(store) {
      store.getAssigned();
    },
  }),
);
