<div
  class="flex flex-row items-center w-full text-base cursor-pointer"
  [routerLink]="['/limits/preview/', limitViolation.userLimit.id]"
>
  <div
    class="icon spacer"
    matTooltip="{{
      'user-limit.notification.userlimitviolation.TITLE' | transloco
    }}"
  >
    <fa-icon [icon]="faBoltSlash" class=""></fa-icon>
  </div>
  <div class="flex flex-col overflow-hidden w-full">
    <div class="text-primary-color">
      <span>
        {{ 'user-limit.notification.userlimitviolation.VIOLATION' | transloco }}
      </span>
      -
      <span>
        <span>{{ limitViolation.userLimit.caption }}</span>
      </span>
    </div>

    <div>
      <span class="italic">
        {{
          'user-limit.notification.userlimitviolation.TRADE_DATE' | transloco
        }}:
      </span>
      <span>{{ limitViolation.tradeDate | date: 'mediumDate' }}</span>
    </div>

    <div>
      <span class="italic">
        {{
          'user-limit.notification.userlimitviolation.SIGNALDATE' | transloco
        }}:
      </span>
      <span>{{ limitViolation.signalDate | date: 'mediumDate' }}</span>
    </div>

    <div>
      <span class="italic">
        {{
          'user-limit.notification.userlimitviolation.EXPRESSION' | transloco
        }}:
      </span>
      <span>{{ limitViolation.expression }}</span>
    </div>

    <div>
      <span class="italic">
        {{
          'user-limit.notification.userlimitviolation.RESOLVED_EXPRESSION'
            | transloco
        }}:
      </span>
      <span>{{ limitViolation.resolvedExpression }}</span>
    </div>
  </div>
</div>
