import {
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  Router,
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withRouterConfig,
} from '@angular/router';
import { TailwindThemeService } from '@backoffice-frontend/shared/ui-tailwind';
import {
  LocaleProvider,
  translocoProviders,
} from '@backoffice-frontend/shared/util-transloco';
import { authErrorInterceptor } from '@clean-code/shared/auth/util-auth';
import { AzureAuthModule } from '@clean-code/shared/auth/util-azure';
import { MatPaginationIntlService } from '@clean-code/shared/components/ui-mat-table';
import { EnvConfig, provideConfig } from '@clean-code/shared/util-config';
import { dateHttpInterceptor } from '@clean-code/shared/util-graphql';
import { provideCustomNativeDateAdapter } from '@clean-code/shared/util/util-date';
import {
  GlobalErrorHandlerService,
  errorInterceptorService,
} from '@clean-code/shared/util/util-error';
import {
  USER_LIMIT_SERVICE,
  UserLimitAdminService,
} from '@clean-code/user-limit/domain';
import { RxState } from '@rx-angular/state';
import * as Sentry from '@sentry/angular-ivy';
import { NgxPermissionsModule } from 'ngx-permissions';
import { provideToastr } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppModule } from './app.module';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    providers(),
    provideCustomNativeDateAdapter(),
    // experimental, remove if not needed
    // provideExperimentalZonelessChangeDetection(),
    provideAnimationsAsync(),
    provideConfig(environment as EnvConfig),

    // provideAzureAuth(environment.auth),
    // providers(),
    importProvidersFrom(
      AppModule,
      AzureAuthModule.forRoot(environment.auth),
      NgxPermissionsModule.forRoot(),
    ),
    // providers(),
    provideRouter(
      appRoutes,
      withComponentInputBinding(),
      withEnabledBlockingInitialNavigation(),
      // withDebugTracing(),
      withRouterConfig({ paramsInheritanceStrategy: 'always' }),
    ),

    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([
        authErrorInterceptor,
        // graphqlErrorInterceptor,
        dateHttpInterceptor,
        errorInterceptorService,
      ]),
    ),

    provideToastr(),
    translocoProviders,
  ],
};

// const ScriptStore: Scripts[] = [
//   {
//     name: 'html2canvas',
//     src: '../assets/scripts/html2canvas/html2canvas.min.js',
//   },
//   { name: 'pdfmake', src: '../assets/scripts/pdfmake/pdfmake.min.js' },
//   {
//     name: 'katex',
//     src: '../assets/scripts/katex/katex@0.16.8.min.js',
//   },
// ];

function providers() {
  return [
    RxState,
    LocaleProvider,
    TailwindThemeService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginationIntlService,
    },
    {
      provide: USER_LIMIT_SERVICE,
      useClass: UserLimitAdminService,
    },
  ];
}
