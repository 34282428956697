import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Message,
  Notification,
} from '@backoffice-frontend/notifications/util-api';
import { LanguageService } from '@backoffice-frontend/shared/util-transloco';
import { DateParserService } from '@clean-code/shared/common';
import { faBoltSlash } from '@fortawesome/pro-light-svg-icons';
import { UserLimitViolation } from '../../models/user-limit-violation';

@Component({
  selector: 'violation-notification-item',
  templateUrl: './user-limit-violation-notification-item.component.html',
  styleUrls: ['./user-limit-violation-notification-item.component.scss'],
})
export class UserLimitViolationNotificationItemComponent
  implements Notification, OnInit
{
  public faBoltSlash = faBoltSlash;

  @Input()
  public message: Message;

  public limitViolation: UserLimitViolation = {} as UserLimitViolation;

  private language: string;

  constructor(private router: Router, languageService: LanguageService) {
    this.language = languageService.currentLocale;
  }

  public ngOnInit() {
    if (this.message && this.message.data) {
      this.limitViolation = <UserLimitViolation>(
        JSON.parse(this.message.data, DateParserService.getJsonDateReviver())
      );
    }
  }

  public itemClicked(/*$event: Event*/) {
    this.router.navigate([
      '/limits/preview/' + this.limitViolation.userLimit.id,
    ]);
  }

  // public get translatedProductName(): string {
  //   if (this.limitViolation.product.additionalData) {
  //     const kvp = this.limitViolation.product.additionalData.find(
  //       (x) => x.key === 'caption-' + this.language
  //     );
  //     if (kvp) {
  //       return kvp.value as string;
  //     }
  //   }
  //   //fallback
  //   return this.limitViolation.product.caption;
  // }
}
