
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { WidgetBaseFilterComponent } from '@backoffice-frontend/shared/bo/widgets/ui-widget-base-filter';
import {
  TimeSeriesAnalysisIdentifierDataLocalized,
  TimeSeriesDynamicSelectionComponent,
  TimeSeriesInput,
} from '@backoffice-frontend/time-series-identifier/api';
import { TimeSeriesIdentifierInputForm } from '@backoffice-frontend/time-series-identifier/domain';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import { BaseWidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/api-widget';
import { PatchFormGroupValuesDirective } from '@clean-code/shared/directives/ui-form';
import { Observable } from 'rxjs';
@Component({
  standalone: true,
  selector: 'dashboard-market-overview-widget-config',
  templateUrl: './market-overview-widget-config.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    PatchFormGroupValuesDirective,
    WidgetBaseFilterComponent,
    ProgressBarComponent,
    TimeSeriesDynamicSelectionComponent
],
})
export class MarketOverviewWidgetConfigComponent extends BaseWidgetTypeConfigComponent<TimeSeriesInput> {
  public timeSeries$: Observable<TimeSeriesAnalysisIdentifierDataLocalized[]>;

  override form = new UntypedFormGroup({
    identifier: new TimeSeriesIdentifierInputForm(),
  });

  public close() {
    //TODO: dashboard state
    // this.callback.next();
  }
}
