import {
  Injectable,
  type OnDestroy,
  effect,
  inject,
  isDevMode,
} from '@angular/core';
import { AuthStore } from '@clean-code/shared/auth/util-auth';
import {
  type HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import { BehaviorSubject, type Observable, Subject } from 'rxjs';
import { SignalRRetryPolicy } from '../application/signalr-retry-policy';
import type { SignalRSettings } from '../application/signalr.settings';

@Injectable()
export abstract class SignalRService implements OnDestroy {
  #authStore = inject(AuthStore);
  protected hubConnection: HubConnection;
  private name: string;

  private connected$ = new BehaviorSubject<HubConnectionState>(
    HubConnectionState.Disconnected,
  );
  protected closeSubject = new Subject<void>();

  constructor(settings: SignalRSettings) {
    if (!settings || !settings.url) {
      throw new Error('settings null or settings connection not defined!');
    }

    this.name = settings.name;

    const logLevel = isDevMode() ? LogLevel.Information : LogLevel.Error;
    const retryPolicy = new SignalRRetryPolicy();

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(settings.url, settings.options)
      .configureLogging(logLevel)
      .withAutomaticReconnect(retryPolicy)
      .build();

    //reconnect logic
    this.hubConnection.onreconnecting((error) => {
      console.error(error);
      console.warn(`reconnecting signalR connection for '${this.name}'`);
      this.connected$.next(this.hubConnection.state);
    });

    this.hubConnection.onreconnected((connectionId) => {
      console.log(
        `Reconnect for '${this.name}' successful, connectionId: '${connectionId}'`,
      );
      this.connected$.next(this.hubConnection.state);
    });

    effect(() => {
      const accessToken = this.#authStore.accessToken();

      if (accessToken) {
        this.startConnection(accessToken);
      }
    });
    // setTimeout(() => {
    // }, 5000);
  }

  public get onConnected$(): Observable<HubConnectionState> {
    return this.connected$;
  }

  protected abstract registerHubHandlers(): void;
  protected abstract removeHubHandlers(): void;

  ngOnDestroy(): void {
    this.stopConnection();

    this.closeSubject.next();
    this.closeSubject.complete();
  }

  private startConnection(accessToken: string): void {
    if (!accessToken) {
      //only disconnect if not disconnected
      if (this.hubConnection.state !== HubConnectionState.Disconnected) {
        this.stopConnection();
      }
    } else {
      //only connect if disconnected
      if (this.hubConnection.state !== HubConnectionState.Disconnected) {
        return;
      }

      this.hubConnection
        .start()
        .then(
          () => {
            console.log(`Connection started for '${this.name}'`);
            this.registerHubHandlers();
            this.connected$.next(this.hubConnection.state);
            return Promise.resolve();
          } /*,
            (reason) => {
              console.error(reason);
              return Promise.reject(reason);
            }*/,
        )
        .catch((error) => {
          console.error(
            `Error while starting connection for '${this.name}': ${error}`,
          );
          // if (isDevMode) {
          //   throw error;
          // }
        });
    }
  }

  private stopConnection(): void {
    this.removeHubHandlers();

    this.hubConnection.stop().then(
      () => {
        console.log(`connection stopped successfully for ${this.name}`);
        this.connected$.next(this.hubConnection.state);
      },
      (rejected) => {
        console.error(`connection could not be stopped, reason: ${rejected}`);
        this.connected$.next(this.hubConnection.state);
      },
    );
  }
}
