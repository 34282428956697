import { Route } from '@angular/router';
import { DashboardPanelGridComponent } from './dashboard-panel-grid/dashboard-panel-grid.component';
import { DashboardPanelComponent } from './dashboard-panel/dashboard-panel.component';

export const dashboardRoutes: Route = {
  path: 'dashboard',
  loadComponent: () =>
    import('./dashboard/dashboard.component').then(
      (mod) => mod.DashboardComponent
    ),
  children: [
    {
      path: '',
      component: DashboardPanelGridComponent,
    },
    {
      path: ':id',
      component: DashboardPanelComponent,
    },
  ],
};
