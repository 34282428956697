@if (isLoading$ | async) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
<div class="dashboard-edit-component flex flex-col p-3 mt-2">
  <div [formGroup]="form" [patchFormGroupValues]="dashboard$ | async">
    <div class="form-row">
      <div class="form-group col-lg-12 flex flex-col sm:flex-row items-center">
        <cc-input-translation
          [containerClasses]="'name-container'"
          [label]="'DASHBOARDPANEL.PROPERTIES.NAME'"
          [required]="true"
          formControlName="name"
        ></cc-input-translation>
        <cc-color-picker formControlName="color"></cc-color-picker>
      </div>
    </div>

    <div formContainerFormRow>
      <div class="form-group col-lg-12">
        <cc-input-translation
          [containerClasses]="'description-container'"
          [label]="'DASHBOARDPANEL.PROPERTIES.DESCRIPTION'"
          [longText]="true"
          formControlName="description"
        ></cc-input-translation>
      </div>
    </div>

    <div formContainerFormRow>
      <mat-form-field>
        <mat-label>{{ 'common.TENANT' | transloco }}</mat-label>
        <mat-select formControlName="tenant" id="tenant" multiple>
          @for (tenant of tenants$ | async; track tenant) {
            <mat-option value="{{ tenant.tenant }}"
              >{{ tenant.tenant }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      @if (tenant.errors?.['tenantIsNotValid']) {
        <div
          class="text-sm leading-tight text-red-600"
          class="text-sm leading-tight text-red-600"
        >
          {{ 'DASHBOARDPANEL.TENANT_NOT_ALLOWED' | transloco }}
        </div>
      }
    </div>

    <mat-checkbox #shared formControlName="shared">{{
      'DASHBOARDPANEL.PROPERTIES.SHARED' | transloco
    }}</mat-checkbox>
    @if (shared.checked) {
      <div class="form-row">
        <div class="form-group col-lg-12">
          <cc-visible-to formControlName="visibleTo"></cc-visible-to>
        </div>
      </div>
    }
  </div>
</div>
