import { Route } from '@angular/router';
import { limitReportPowerDetails } from '@backoffice-frontend/limit-report/ui-power';

export const limitReportPowerRoute: Route = {
  path: '',
  children: [
    {
      path: '',
      loadChildren: () =>
        import('@backoffice-frontend/limit-report/ui-power').then(
          (mod) => mod.LimitReportPowerListModule
        ),
    },
    limitReportPowerDetails,
  ],
};
