<ng-select
  [formControl]="formControl"
  [items]="data$ | async"
  [multiple]="true"
  [searchable]="true"
  bindLabel="caption"
  appendTo="body"
  bindValue="key"
>
  <ng-template let-item="item" ng-label-tmp>
    {{ item.caption | transloco }}
  </ng-template>
  <ng-template
    let-index="index"
    let-item="item"
    let-search="searchTerm"
    ng-option-tmp
  >
    {{ item.caption | transloco }}
  </ng-template>
</ng-select>
