import { HttpTransportType, IHttpConnectionOptions } from '@microsoft/signalr';

export class SignalRSettings {
  url: string;
  options: IHttpConnectionOptions;
  name: string;

  constructor(data: {
    url: string;
    options: IHttpConnectionOptions;
    name: string;
  }) {
    if (data.url) {
      this.url = data.url;
    }
    if (data.options) {
      this.options = data.options;
    }

    if (data.name) {
      this.name = data.name;
    }

    this.options.transport =
      this.options.transport || HttpTransportType.WebSockets;
    this.options.skipNegotiation = true;
  }
}
