export const getWidgetDetailParams = `
        id
        type
        name {
                ...localizationMediumFragment
        }
        description {
                ...localizationLongFragment
        }
        color
        shared
        visibleTo
        config
        createdBy
        creator {
                id
                fullName
        }
`;

export const getWidgetDetailWithTranslationsParams = `
        id
        type
        name {
                id
                localizations {
                        id
                        cultureCode
                        value
                }
        }
        description {
                id
                localizations {
                        id
                        cultureCode
                        value
                }
        }
        color
        shared
        visibleTo
        config
        createdBy
        creator {
           id
           fullName
        }
`;
