<div mat-dialog-title class="flex flex-row justify-between">
  <h4 transloco="DASHBOARDWIDGET.DIALOG-TITLE.ADD"></h4>

  <button mat-icon-button (click)="onClose()">
    <fa-icon [icon]="faTimes" size="xl"></fa-icon>
  </button>
</div>
<div class="flex flex-col h-full overflow-y-auto">
  <!-- <h1 mat-dialog-title>{{ 'DASHBOARDWIDGET.DIALOG-TITLE.ADD' | transloco }}</h1> -->

  <dashboard-widget-edit
    (callback)="onAddedResult($event)"
  ></dashboard-widget-edit>
</div>
