<ng-template #headerTemplate let-data="data">
  <div class="h-6 mr-3 flex justify-end flex-shrink-0 space-x-1">
    <fa-icon
      [icon]="faFileSpreadsheet"
      (click)="export()"
      class="text-base cursor-pointer hover:text-primary-color"
      *ngxPermissionsOnly="exportTablePermissions.exportTableExport"
    ></fa-icon>
    <fa-icon
      [icon]="faFileExcel"
      (click)="exportData()"
      class="text-base cursor-pointer hover:text-primary-color"
      *ngxPermissionsOnly="exportTablePermissions.exportTableExport"
    ></fa-icon>
    <fa-icon
      [icon]="faFilters"
      (click)="fieldChooser()"
      class="text-base cursor-pointer hover:text-primary-color"
      *ngxPermissionsOnly="exportTablePermissions.exportTableFilter"
    ></fa-icon>
  </div>
</ng-template>
<export-table [params]="config$ | async"></export-table>
