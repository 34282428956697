@if (widgets(); as widgets) {
  <gridster #gridster [options]="options">
    @for (item of widgets; track trackByFn($index, item)) {
      <gridster-item [item]="item">
        <dashboard-dashboard-panel-widget
          [widget]="item"
        ></dashboard-dashboard-panel-widget>
      </gridster-item>
    }
  </gridster>
}

<!-- <div class="empty" *ngIf="!widgets?.length && !hasWritePermissions()">
{{ 'DASHBOARDPANEL.NOWIDGETS.NOTCREATOR' | transloco }}
</div>

<div class="empty" *ngIf="!widgets?.length && hasWritePermissions()">
  {{ 'DASHBOARDPANEL.NOWIDGETS.CREATOR' | transloco }}
</div>
-->
