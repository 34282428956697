import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { WidgetBaseFilterComponent } from '@backoffice-frontend/shared/bo/widgets/ui-widget-base-filter';
import { BaseWidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/api-widget';
import { PatchFormGroupValuesDirective } from '@clean-code/shared/directives/ui-form';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@jsverse/transloco';
import { map } from 'rxjs/operators';
import { MarketCustomerAnalysisWidgetTypeConfig } from '../market-customer-analysis-widget.models';
import { MarketCustomerAnalysisWidgetService } from '../services/market-customer-analysis-widget.service';
import { WidgetCompany } from '../widget-company';
@Component({
  standalone: true,
  selector: 'dashboard-market-customer-analysis-widget-config',
  templateUrl: './market-customer-analysis-widget-config.component.html',
  styleUrls: ['./market-customer-analysis-widget-config.component.css'],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    NgSelectModule,
    TranslocoModule,

    PatchFormGroupValuesDirective,
    WidgetBaseFilterComponent,
  ],
})
export class MarketCustomerAnalysisWidgetConfigComponent extends BaseWidgetTypeConfigComponent<MarketCustomerAnalysisWidgetTypeConfig> {
  override form = new UntypedFormGroup({
    companyId: new UntypedFormControl('', Validators.required),
  });

  private companyService = inject(MarketCustomerAnalysisWidgetService);
  public companies$ = this.companyService
    .getAllCompanies$()
    .pipe(map((data: WidgetCompany[]) => data.filter((x) => !x.disabled)));

  public close() {
    //TODO: dashboard state
    // this.callback.next();
  }
}
