<div class="widget-reference-component">
  @if (isLoading$ | async) {
    <cc-loading-indicator></cc-loading-indicator>
  }
  @for (widget of widgets$ | async; track widget) {
    <widget-preview
      [widget]="widget"
      (click)="toggleSelection(widget)"
      class="px-2"
      [ngClass]="{ selected: isSelected(widget) }"
      >
    </widget-preview>
  }

  @if ((widgets$ | async)?.length === 0) {
    <div class="empty">
      {{ 'DASHBOARDPANEL.NOWIDGETS.REFERENCED' | transloco }}
    </div>
  }
</div>
