import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NotificationStateService } from '@clean-code/notifications/domain';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBell, faBellOn } from '@fortawesome/pro-light-svg-icons';
import { TranslocoModule } from '@jsverse/transloco';
import { HubConnectionState } from '@microsoft/signalr';
import { RxState } from '@rx-angular/state';
import { RxLet } from '@rx-angular/template/let';
import { NotificationHostComponent } from './notification-host/notification-host.component';

@Component({
  standalone: true,
  selector: 'notifications-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,

    FontAwesomeModule,

    MatMenuModule,
    TranslocoModule,
    RxLet,

    NotificationHostComponent,
  ],
  providers: [RxState, NotificationStateService],
})
export class NotificationCenterComponent {
  public faBell = faBell;
  public faBellOn = faBellOn;
  public connectedState = HubConnectionState.Connected;

  public state$ = this.notificationStateService.state$;

  constructor(private notificationStateService: NotificationStateService) {}

  public setAllRead($event: Event): void {
    this.notificationStateService.setAllAsRead();
  }
}
