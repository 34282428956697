<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>

<div mat-dialog-title class="!flex">
  <h2 class="dialog-title">
    {{ this.data.widget.name.localizations[0].value }}
  </h2>

  <fa-icon
    class="cursor-pointer"
    [icon]="faXmarkLarge"
    mat-dialog-close
  ></fa-icon>
</div>
<mat-dialog-content>
  <cc-time-span-toggle [timeFrame]="selectedTimeFrame$"></cc-time-span-toggle>
  <div class="mt-5">
    <fof-comparison-chart
      class="h-full px-1"
      [params]="data$ | async"
    ></fof-comparison-chart>
  </div>
</mat-dialog-content>
