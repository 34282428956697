<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>
@if (data$ | async; as data) {
  <table
    class="table-layout min-w-full divide-y divide-gray-200"
    >
    <thead>
      <tr>
        <th scope="col">
          {{ 'master-data.COMPANY' | transloco }}
        </th>
        <th>
          {{ 'master-data.VOLUME' | transloco }}
        </th>
      </tr>
    </thead>
    <tbody class="overflow-y-scroll">
      <!-- Odd row -->
      @for (element of data; track element) {
        <tr
          class="border-b"
          global-tooltip="{{ (element.value.volume | number: '.2-2') + ' GWh' }}"
          >
          @if (config?.filter.energySource; as energySource) {
            <td
              class="whitespace-nowrap text-sm font-medium text-primary-color hover:text-black"
        [routerLink]="[
          '/company-' +
            energySource.toLowerCase() +
            '/' +
            element.value.companyId +
            '/' +
            energySource.toLowerCase() +
            '/orders/hkn/orders'
        ]"
              >
              {{ element.key }}
            </td>
          }
          <td class="whitespace-nowrap text-sm text-gray-500">
            {{ element.value.volume | number: '1.0-0' }} GWh
          </td>
        </tr>
      }
    </tbody>
  </table>
}
