import { Injectable } from '@angular/core';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { KamDropDown } from './kam-dropdown';

@Injectable({ providedIn: 'root' })
export class KamDropDownService {
  private url = this.configService.settings['graphql'];

  constructor(
    private graphqlService: GraphqlService,
    private configService: ConfigService
  ) {}

  public getAllPowerKeyAccountManager$(
    searchString: string
  ): Observable<KamDropDown[]> {
    const query = `
    query($searchString: String)  {
      powerKeyaccountManager(searchString: $searchString) {
        id,
        fullName
      }
    }
  `;

    const variables = { searchString: searchString };

    return this.graphqlService.query<KamDropDown[]>(query, variables, this.url);
  }

  public getAllGasKeyAccountManager$(
    searchString: string
  ): Observable<KamDropDown[]> {
    const query = `
    query($searchString: String)  {
      gasKeyaccountManager(searchString: $searchString) {
        id,
        fullName
      }
    }
  `;

    const variables = { searchString: searchString };
    return this.graphqlService.query<KamDropDown[]>(query, variables, this.url);
  }
}
