import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AreaDropdownComponent } from '@backoffice-frontend/shared/bo/components/ui-area-dropdown';
import { EnumService } from '@backoffice-frontend/shared/bo/util-masterdata';
import { BaseFormulaTypeConfigComponent } from '@clean-code/feature/formula-builder/domain';
import { ID } from '@clean-code/shared/common';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { conditionalValidator } from '@clean-code/shared/util/util-form';
import { TranslocoModule } from '@jsverse/transloco';

export enum MarketToMarketComponentTypes {
  MethodeType = 'MarketToMarketFormulaMethod',
}

@Component({
  standalone: true,
  templateUrl: './market-to-market-method.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    //dateModules(),
    MatNativeDateModule,
    AreaDropdownComponent,

    TranslocoModule,
    UtilDateModule,
  ],
})
export class MarketToMarketMethodeComponent extends BaseFormulaTypeConfigComponent {
  private enumService = inject(EnumService);

  public timeFrameTypes$ = this.enumService.getEnumType$('TimeFrameType');

  formGroup = new FormGroup({
    startDate: new FormControl<Date>(null),
    timeFrameType: new FormControl<string>(null),
    value: new FormControl<number>(null, [
      conditionalValidator(
        () => this.formGroup.get('timeFrameType').value != null,
        Validators.required
      ),
    ]),
    marketAreaId: new FormControl<ID>(null),
  });
}
