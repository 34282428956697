import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ExportTablePermissions } from '@backoffice-frontend/market/domain';
import { WidgetTypeConfig } from '@backoffice-frontend/time-series-identifier/api';
import { BaseChartWidget } from '@clean-code/shared/components/ui-dashboard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faFileExcel,
  faFileSpreadsheet,
  faFilters,
} from '@fortawesome/pro-solid-svg-icons';
import { NgxPermissionsModule } from 'ngx-permissions';

import { ExportTableComponent } from '../export-table/export-table.component';

export enum ExportWidgetTypes {
  WidgetType = 'ExportTableWidget',
}

@Component({
  standalone: true,
  templateUrl: './export-widget.component.html',
  styleUrls: ['./export-widget.component.css'],
  imports: [
    CommonModule,
    NgxPermissionsModule,
    ExportTableComponent,
    FontAwesomeModule,
  ],
})
export class ExportWidgetComponent extends BaseChartWidget<WidgetTypeConfig> {
  faFileSpreadsheet = faFileSpreadsheet;
  faFileExcel = faFileExcel;
  faFilters = faFilters;

  @ViewChild(ExportTableComponent, { static: false })
  exportTable: ExportTableComponent;

  public exportTablePermissions = ExportTablePermissions;

  updateChart(): void {
    this.exportTable?.pivotGrid.instance.repaint();
  }

  fieldChooser() {
    this.exportTable.pivotGrid.instance.getFieldChooserPopup().show().then();
  }

  export() {
    this.exportTable.onExporting();
  }

  exportData() {
    this.exportTable.exportData();
  }
}
