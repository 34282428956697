import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { MatProgressBar } from '@angular/material/progress-bar';
import { DashboardFacade } from '@clean-code/shared/dashboard/dashboard/domain';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEdit, faPlus } from '@fortawesome/pro-light-svg-icons';

/**
 * Panel grid - if we want to show panel in a grid. Until then this is not used
 */
@Component({
  standalone: true,
  selector: 'dashboard-panel-grid-component',
  templateUrl: './dashboard-panel-grid.component.html',
  styleUrls: ['./dashboard-panel-grid.component.css'],
  imports: [CommonModule, MatProgressBar, FontAwesomeModule],
})
export class DashboardPanelGridComponent {
  public faEdit = faEdit;
  public faPlus = faPlus;
  public isLoading = this.dashboardFacade.isLoading;

  public userDashboards$ = this.dashboardFacade.getAssigned$();

  constructor(
    private dashboardFacade: DashboardFacade, //private confirmService: ConfirmService,
  ) {}

  public hiddenCard = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public addDashboard() {
    // const column = {
    //   dashboardId: params[1],
    //   position: params[0],
    // };
    // this.confirmService.open('', '', DashboardAddDialogComponent, column, { width: '400px' }).subscribe((column: DialogResult<IDashboardPanel>) => {
    //   if (column?.value) {
    //     this.dashboardFacade.add$(column.value).subscribe();
    //   }
    // });
  }
}
