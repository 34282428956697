import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import {
  AvailablePermission,
  BalancingEnergyPermissions,
  ContactsPermissions,
  ContractsPermissions,
  CustomerPoolPermissions,
  EnergyOrderGasPermissions,
  EnergyOrderPowerPermissions,
  ForecastPermissions,
  ForwardPriceCurvePermissions,
  LimitReportPermissions,
  LoadProfilePermissions,
  MonitoringPermissions,
  OfferPermissions,
  SettingPermissions,
  StandardizedLoadProfilePermissions,
  SurchargePermissions,
  UserLimitPermissions,
} from '@backoffice/shared/bo/util-permissions';
import { DashboardMenuComponent } from '@clean-code/shared/dashboard/dashboard/feature-dashboard-menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCalculator,
  faChartBar,
  faChartLine,
  faCogs,
  faCommentAlt,
  faDesktopAlt,
  faDollarSign,
  faEuroSign,
  faFileAlt,
  faFileContract,
  faFileInvoice,
  faGamepadModern,
  faIndustry,
  faMapMarker,
  faMoneyBillAlt,
  faNewspaper,
  faUserFriends,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  standalone: true,
  selector: 'app-navigation',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    RouterModule,

    MatExpansionModule,

    FontAwesomeModule,
    NgxPermissionsModule,
    TranslocoModule,
    DashboardMenuComponent,
  ],
})
export class SidebarComponent {
  @HostBinding('class') class = 'app-sidebar';

  faIndustry = faIndustry;
  faChartLine = faChartLine;
  faChartBar = faChartBar;
  faFileInvoice = faFileInvoice;
  faCommentAlt = faCommentAlt;
  faFileAlt = faFileAlt;
  faUsers = faUsers;
  faUserFriends = faUserFriends;
  faCogs = faCogs;
  faDollarSign = faDollarSign;
  faMoneyBillAlt = faMoneyBillAlt;
  faMapMarker = faMapMarker;
  faNewspaper = faNewspaper;
  faCalculator = faCalculator;
  faEuroSign = faEuroSign;
  faFileContract = faFileContract;
  faDesktopAlt = faDesktopAlt;
  faGamepadModern = faGamepadModern;

  public permissions = AvailablePermission;
  public contactsPermisssion = ContactsPermissions;
  public contactsRootNode =
    this.contactsPermisssion.companySideNavPermisssions.concat(
      this.contactsPermisssion.personSideNavPermisssions
    );
  public loadProfilePermisssion = LoadProfilePermissions;
  public standardizedLoadProfile = StandardizedLoadProfilePermissions;

  public loadProfileRoot =
    this.loadProfilePermisssion.loadProfileTabPermisssion.concat(
      this.standardizedLoadProfile.standardizedLoadProfileTabPermisssion
    );

  public forecastPermissions = ForecastPermissions;

  public surchargePermissions = SurchargePermissions;
  public forwardPriceCurvePermissions = ForwardPriceCurvePermissions;
  public offerPermissions = OfferPermissions;

  public settingPermissions = SettingPermissions;
  public balancingEnergyPermissions = BalancingEnergyPermissions;

  public costingRoot = [ForwardPriceCurvePermissions.manage]
    .concat([this.surchargePermissions.admin])
    .concat(this.offerPermissions.gasSideBarPermisssion)
    .concat(this.offerPermissions.powerSideBarPermisssion);

  public contractsPermissions = ContractsPermissions;
  public contractRoot = [
    ContractsPermissions.contractsPowerContract,
    ContractsPermissions.contractsGasContract,
  ];

  public energyOrderPowerPermissions = EnergyOrderPowerPermissions;
  public energyOrderGasPermissions = EnergyOrderGasPermissions;

  public energyOrderRootPermissions =
    this.energyOrderPowerPermissions.sideBarPermisssion.concat(
      this.energyOrderGasPermissions.sideBarPermisssion
    );

  public settingsSideNavPermisssions = [
    AvailablePermission.manageUsersPermission,
    AvailablePermission.manageRolesPermission,
    this.contactsPermisssion.settingAdmin,
    this.contactsPermisssion.settingPowerUser,
  ];

  public limitReportPermissions = LimitReportPermissions;

  public limitReportsSideNavPermissions =
    LimitReportPermissions.sideNavPermission;

  public userLimitPermissions = UserLimitPermissions;
  public userLimitSideNavPermissions = UserLimitPermissions.sideNavPermission;

  public monitoringPermissions = MonitoringPermissions;
  healthCheckSideNavPermissions = MonitoringPermissions.sideNavPermission;

  public customerPoolPermissions = CustomerPoolPermissions;

  public customerPoolsSideNavPermissions =
    CustomerPoolPermissions.sideNavPermission;
}
