import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { PersistStateService } from '@clean-code/shared/common';
import config from 'devextreme/core/config';
import { SentryHelper } from 'shared/util/util-sentry-logging';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { licenseKey } from './devextreme-license';
import { environment } from './environments/environment';
 
config({ licenseKey });  
if (environment.production) {
  enableProdMode();

  SentryHelper.init({
    dsn: 'https://e6b84e90ae28481d94c1eeb5c7b7954b@o362612.ingest.sentry.io/4368102',
    environment: environment.sentryEnv,
    networkDetailAllowUrls: [
      environment.api,
      `${environment.api}/userProfile/me`,
      environment.graphql,
    ],
    ignoreErrors: ['/ResizeObserver loop/'],
    tracePropagationTargets: [
      'localhost',
      environment.api,
      environment.graphql,
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

PersistStateService.persistState(['auth']);

(window as any).CC = {
  version: environment.version,
};

bootstrapApplication(AppComponent, appConfig)
  .then((ref) => {
    // Ensure Angular destroys itself on hot reloads.
    (window as any)['ngRef']?.destroy();

    (window as any)['ngRef'] = ref;

    // Otherwise, log the boot error
  })
  .catch((err) => console.error(err));

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .then(() => {
//     if ('serviceWorker' in navigator && environment.production) {
//       navigator.serviceWorker.register('ngsw-worker.js');
//     }
//   })
//   .catch((err) => console.error(err));
