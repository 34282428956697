<div searchRow>
  <search-table-filter [formControl]="searchControl"></search-table-filter>

  <div [formGroup]="filterControl" searchRow>
    <bo-energy-source-dropdown
      [includeEmpty]="true"
      formControlName="energySource"
    ></bo-energy-source-dropdown>
  </div>
</div>
