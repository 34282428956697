
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BaseFormulaTypeConfigComponent } from '@clean-code/feature/formula-builder/domain';
import { TranslocoModule } from '@jsverse/transloco';
import { CustomDataSourceForm } from './custom-datasource';

export enum CustomDatSourceType {
  ConfigType = 'CustomDataSource',
}

@Component({
  standalone: true,
  templateUrl: './custom-datasource.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslocoModule
],
})
export class CustomDataSourceComponent extends BaseFormulaTypeConfigComponent {
  formGroup = new FormGroup<CustomDataSourceForm>({
    identifier: new FormControl('', { nonNullable: true }),
  });
}
