<h2 mat-dialog-title>{{ 'DASHBOARDPANEL.DIALOG-TITLE.ADD' | transloco }}</h2>

<mat-dialog-content>
  <mat-horizontal-stepper [linear]="false" #stepper>
    <mat-step [stepControl]="editForm">
      <ng-template matStepLabel>{{
        'DASHBOARDPANEL.DIALOG-TITLE.CREATE' | transloco
      }}</ng-template>

      <ng-template matStepContent>
        <dashboard-dashboard-edit
          #editChild
          [dashboard]="dashboard"
        ></dashboard-dashboard-edit>
      </ng-template>
    </mat-step>
    <mat-step [stepControl]="referenceForm" [editable]="">
      <ng-template matStepLabel>{{
        'DASHBOARDPANEL.DIALOG-TITLE.REFERENCE' | transloco
      }}</ng-template>

      <ng-template matStepContent>
        <dashboard-dashboard-reference #referenceChild>
        </dashboard-dashboard-reference>
      </ng-template>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <cc-cancel-action-button (onClick)="close()"></cc-cancel-action-button>
  <cc-save-action-button (onClick)="save()"></cc-save-action-button>
</mat-dialog-actions>
