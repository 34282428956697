import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  ViewChild,
  inject,
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSort, MatSortModule, MatSortable } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Configuration,
  HealthCheckDetail,
  HealthCheckFacade,
  HealthCheckWithDetails,
  MonitoringDomainModule,
  Settings,
} from '@clean-code/monitoring/domain';
import { StatusSymbolComponent } from '@clean-code/monitoring/ui-status-symbol';
import { ID, indicate } from '@clean-code/shared/common';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import {
  DataTableContainerComponent,
  TablePreviewComponent,
  TableStateService,
} from '@clean-code/shared/components/ui-mat-table';
import { PageContainerComponent } from '@clean-code/shared/templates/ui-tailwind-full-width';
import { PipesModule } from '@clean-code/shared/ui-pipes';
import { ToastService } from '@clean-code/shared/util/util-toast';
import {
  faExclamationCircle,
  faPlay,
  faSpinner,
} from '@fortawesome/pro-light-svg-icons';
import {
  TRANSLOCO_SCOPE,
  TranslocoModule,
  TranslocoService,
} from '@jsverse/transloco';
import dayjs from 'dayjs';
import {
  BehaviorSubject,
  Subject,
  type Subscription,
  catchError,
  combineLatest,
  filter,
  first,
  map,
  repeat,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { DateTimeCommaCustomPipe } from './pipes/date-time-comma-custom.pipe';
import { GermanDateFormatCustomPipe } from './pipes/german-date-format-custom.pipe';

@Component({
  standalone: true,
  selector: 'monitoring-healthcheck',
  templateUrl: './healthcheck.component.html',
  styleUrls: ['./healthcheck.component.scss'],
  imports: [
    CommonModule,

    TranslocoModule,

    MatTableModule,
    MatSortModule,
    MatProgressBarModule,

    MonitoringDomainModule,
    ActionButtonModule,
    PipesModule,
    PageContainerComponent,
    DataTableContainerComponent,
    TablePreviewComponent,

    StatusSymbolComponent,
    GermanDateFormatCustomPipe,
    DateTimeCommaCustomPipe,
  ],
  providers: [TableStateService],
})
export class HealthcheckComponent implements AfterViewInit, OnDestroy {
  private healthCheckFacade: HealthCheckFacade = inject(HealthCheckFacade);
  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private toastService: ToastService = inject(ToastService);
  private translateService = inject(TranslocoService);
  private confirmService = inject(ConfirmService);
  private translocoScope = inject(TRANSLOCO_SCOPE);

  // @HostBinding('class') class = 'health-check';
  @HostBinding('class.dataTableList') dataTableList = true;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<Configuration>;

  faPlay = faPlay;
  faSpinner = faSpinner;

  private readonly closeSubject$ = new Subject<void>();
  private readonly stop$ = new Subject<void>();
  private readonly start$ = new Subject<void>();
  private manualStartedServices: Subscription[] = [];

  private services: {
    id: ID;
    executing: boolean;
  }[] = [];

  selectedRow = 0;

  settings$ = new BehaviorSubject<Settings>(null);
  data$ = new BehaviorSubject<Configuration>(null);

  private pollData$ = this.settings$.asObservable().pipe(
    filter((settings) => !!settings),
    switchMap((settings) =>
      timer(0, settings.pollingInterval * 1000).pipe(
        tap(() => this.getData()),
        takeUntil(this.stop$),
        repeat({ delay: (_) => this.start$ }),
      ),
    ),
  );

  dataSource = new MatTableDataSource<HealthCheckWithDetails>();

  displayedColumns: string[] = [
    'operations',
    'name',
    'status',
    'description',
    'details',
    // 'duration',
    // 'tags',
  ];

  pollingToggle = true;

  public isLoading$ = new BehaviorSubject<boolean>(false);

  togglePolling() {
    if (this.pollingToggle) {
      this.stop$.next();
    } else {
      this.start$.next();
    }
    this.pollingToggle = !this.pollingToggle;
  }

  ngAfterViewInit() {
    this.sort.sort({ id: 'name', start: 'asc' } as MatSortable);
    this.dataSource.sort = this.sort;

    this.pollData$.pipe(takeUntil(this.closeSubject$)).subscribe();

    this.healthCheckFacade
      .getUiSettings$()
      .pipe(
        first(),
        tap((setting) => {
          this.settings$.next(setting);
        }),
      )
      .subscribe();
  }

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    //https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    if (!this.canDeactivate()) {
      event.preventDefault();
    }
    return this.canDeactivate();
  }

  // private getData() {
  //   combineLatest([
  //     this.translateService.selectTranslate('HEADER', {}, this.translocoScope),
  //     this.healthCheckFacade
  //       .getApiValues$()
  //       .pipe(indicate(this.isLoading$))
  //       .pipe(
  //         catchError((error) => {
  //           if (error.status === 0 && this.pollingToggle) {
  //             this.togglePolling();
  //             this.toastService.showWarning('monitoring.STOPPING');
  //           }
  //           throw error;
  //         })
  //       ),
  //   ])
  //     .pipe(
  //       map(([_, data]) => data),
  //       tap((data) => {
  //         const populateServiceList = this.services.length === 0;

  //         const newEntries = data.entries.map((x) => {
  //           const entry = x as unknown as HealthCheckWithDetails;
  //           if (populateServiceList) {
  //             this.services.push({ id: entry.id, executing: false });
  //           }

  //           const currentService = this.services.find((x) => x.id === entry.id);
  //           if (currentService) {
  //             currentService.executing = false;
  //           }

  //           if (entry.data) {
  //             const details: string[] = [];

  //             const detailsObjs = <
  //               { nextRun?: HealthCheckDetail; executing?: HealthCheckDetail }
  //             >entry.data;

  //             if (detailsObjs) {
  //               if (detailsObjs.nextRun) {
  //                 details.push(
  //                   this.translateService.translate(
  //                     detailsObjs.nextRun.message,
  //                     {
  //                       date: detailsObjs.nextRun.date,
  //                     }
  //                   )
  //                 );
  //               }
  //               if (detailsObjs.executing) {
  //                 details.push(
  //                   this.translateService.translate(
  //                     detailsObjs.executing.message,
  //                     {
  //                       date: dayjs(detailsObjs.executing.date).format('l LTS'),
  //                     }
  //                   )
  //                 );
  //                 //check if service is running but not started from ui
  //                 if (currentService) {
  //                   currentService.executing = true;
  //                 }
  //               }
  //             }

  //             entry.details = details.join(' - ');
  //           }

  //           return entry;
  //         });

  //         data.entries = newEntries;

  //         this.data$.next(data);

  //         this.dataSource.data = newEntries;
  //         this.table?.renderRows();
  //       }),
  //       first()
  //     )
  //     .subscribe();
  // }

  private setDetails(entries: any[]): any[] {
    const populateServiceList = this.services.length === 0;
    return entries.map((entry: any) => {
      const currentService = this.services.find((x) => x.id === entry.id);
      const transformedEntry = this.transformData(
        entry,
        currentService,
        populateServiceList,
      );
      return transformedEntry;
    });
  }

  private transformData(
    entry: any,
    currentService: any,
    populateServiceList: boolean,
  ): any {
    const entryData = { ...entry };
    if (populateServiceList) {
      this.services.push({ id: entry.id, executing: false });
    }

    if (currentService) {
      currentService.executing = false;
    }

    if (entry.data) {
      const details: string[] = [];
      const detailsObjs = <
        { nextRun?: HealthCheckDetail; executing?: HealthCheckDetail }
      >entry.data;
      if (detailsObjs) {
        if (detailsObjs.nextRun) {
          details.push(
            this.translateService.translate(detailsObjs.nextRun.message, {
              date: detailsObjs.nextRun.date,
            }),
          );
        }
        if (detailsObjs.executing) {
          details.push(
            this.translateService.translate(detailsObjs.executing.message, {
              date: dayjs(detailsObjs.executing.date).format('l LTS'),
            }),
          );
          if (currentService) {
            currentService.executing = true;
          }
        }
      }
      entryData.details = details.join(' - ');
    }

    return entryData;
  }

  private getData() {
    combineLatest([
      this.translateService.selectTranslate('HEADER', {}, this.translocoScope),
      this.healthCheckFacade.getApiValues$().pipe(
        indicate(this.isLoading$),
        catchError((error) => {
          if (error.status === 0 && this.pollingToggle) {
            this.togglePolling();
            this.toastService.showWarning('monitoring.STOPPING');
          }
          throw error;
        }),
      ),
    ])
      .pipe(
        map(([_, data]) => data),
        tap((data) => {
          const newEntries = this.setDetails(data.entries);
          data.entries = newEntries;
          this.data$.next(data);
          this.dataSource.data = newEntries;
          this.table?.renderRows();
        }),
        first(),
      )
      .subscribe();
  }

  viewHistory(row: HealthCheckWithDetails) {
    this.selectedRow = row.id;
    this.router.navigate(['preview/' + row.id], {
      relativeTo: this.activatedRoute,
    });
  }

  executeService(row: HealthCheckWithDetails, $event: PointerEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmService
      .confirm(
        'common.WARNING',
        'monitoring.CONFIRM_EXECUTION',
        true,
        faExclamationCircle,
        '#ffb74d',
      )
      .pipe(
        tap((confirmation) => {
          if (!confirmation) {
            return;
          }

          //to display spinner

          const service = this.services.find((x) => x.id === row.id);
          service.executing = true;

          const sub = this.healthCheckFacade
            .executeService(row.name)
            .pipe(
              first(),
              tap((success) => {
                const index = this.manualStartedServices.findIndex(
                  (x) => x === sub,
                );
                if (index > -1) {
                  this.manualStartedServices.splice(index, 1);
                }

                service.executing = false;
                if (success) {
                  //reload in case of new messages
                  if (!this.pollingToggle) {
                    this.togglePolling();
                  }
                  this.toastService.showSuccess();
                }
              }),
            )
            .subscribe();
          this.manualStartedServices.push(sub);
        }),
        first(),
      )
      .subscribe();
  }

  isServiceExecuting(id: ID) {
    return this.services.find((x) => x.id === id)?.executing;
  }

  canDeactivate() {
    return this.manualStartedServices.length === 0;
  }

  ngOnDestroy(): void {
    this.closeSubject$.next();
    this.closeSubject$.complete();

    this.start$.complete();
    this.stop$.next();
    this.stop$.complete();
  }
}
