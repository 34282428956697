<div mat-dialog-content>
  <div [formGroup]="form" [patchFormGroupValues]="config()">
    <div class="form-row">
      <div class="form-group col-lg-10">
        <ng-select
          [items]="companies$ | async"
          bindLabel="name"
          bindValue="id"
          [multiple]="false"
          appendTo="body"
          [clearable]="false"
          formControlName="companyId"
          placeholder="{{ 'load-profile.fields.COMPANY' | transloco }}"
        >
        </ng-select>
      </div>
    </div>
  </div>
</div>
