import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Message,
  Notification,
} from '@backoffice-frontend/notifications/util-api';
import { DateParserService } from '@clean-code/shared/common';
import {
  faBorderAll,
  faBurn,
  faExclamationTriangle,
  faPlug,
} from '@fortawesome/pro-light-svg-icons';
import { LimitReportBuySignal } from '../models/limit-report-buy-signal';

@Component({
  selector: 'backoffice-frontend-limit-report-buy-signal-notification-item',
  templateUrl: './buy-signal-notification-item.component.html',
  styleUrls: ['./buy-signal-notification-item.component.scss'],
})
export class BuySignalNotificationItemComponent
  implements Notification, OnInit
{
  public faBurn = faBurn;
  public faPlug = faPlug;
  public faBorderAll = faBorderAll;
  public faExclamationTriangle = faExclamationTriangle;

  @Input()
  public message: Message;

  public buySignal: LimitReportBuySignal = {} as LimitReportBuySignal;

  constructor(private router: Router) {}

  public ngOnInit() {
    if (this.message && this.message.data) {
      this.buySignal = <LimitReportBuySignal>(
        JSON.parse(this.message.data, DateParserService.getJsonDateReviver())
      );
    }
  }

  public itemClicked(/*$event: Event*/) {
    this.router.navigate([
      '/limit-reports/' +
        this.buySignal.energySource.toLowerCase() +
        '/limit-report/' +
        this.buySignal.limitReport.id +
        '/overview',
    ]);
  }
}
