import { JsonPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  type TemplateRef,
  ViewChild,
  ViewEncapsulation,
  computed, inject
} from '@angular/core';
import {
  type IDashboardWidgetDetailDto,
  type IWidgetTypeContentComponent,
  WidgetTypeProvider,
} from '@clean-code/shared/components/ui-dashboard';
import { DashboardWidgetFacade } from '@clean-code/shared/dashboard/widget/domain';
import { TranslocoModule } from '@jsverse/transloco';
import { DynamicComponent, DynamicModule } from 'ng-dynamic-component';

@Component({
  standalone: true,
  selector: 'dashboard-widget-type-content',
  templateUrl: './widget-type-content.component.html',
  styleUrls: ['./widget-type-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [JsonPipe, NgTemplateOutlet, DynamicModule, TranslocoModule],
})
export class WidgetTypeContentComponent {
  private widgetTypeProvider = inject(WidgetTypeProvider);
  private widgetFacade = inject(DashboardWidgetFacade);

  @HostBinding('class') class = 'dashboard-widget-type-content';
  public headerTemplate: TemplateRef<any>;

  @ViewChild(DynamicComponent) set component(value: DynamicComponent) {
    this.headerTemplate = (
      value?.componentRef?.instance as IWidgetTypeContentComponent
    )?.headerTemplate;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public outputs: any;

  public widget = this.widgetFacade.widget;
  public inputs = this.widgetFacade.inputs;
  public type = computed(() => ({
    component: this.getComponent(this.widget()),
    inputs: this.inputs(),
  }));

  private getComponent(widget: IDashboardWidgetDetailDto): { new (): IWidgetTypeContentComponent } | undefined {
    const provider = this.widgetTypeProvider.getProvider(widget.type as string);

    return provider?.contentCmp;
  }
}
