import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import {
  LoadProfile,
  LoadProfileService,
} from '@backoffice/load-profile/domain';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import { DataTableBaseComponent } from '@clean-code/shared/components/ui-data-table';
import {
  TableStateService,
  UiMatTableModule,
} from '@clean-code/shared/components/ui-mat-table';
import { PageContainerModule } from '@clean-code/shared/components/ui-page-container';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LoadProfileSearchComponent } from './load-profile-search/load-profile-search.component';

@Component({
  standalone: true,
  selector: 'backoffice-load-profile-list',
  templateUrl: './load-profile-list.component.html',
  styles: ['.icon { font-size:20px; }'],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    TranslocoModule,
    FontAwesomeModule,
    NgxPermissionsModule,
    UiMatTableModule,
    PageContainerModule,
    ActionButtonModule,
    MatTooltipModule,

    LoadProfileSearchComponent,
  ],
  providers: [TableStateService],
})
export class LoadProfileListComponent extends DataTableBaseComponent<LoadProfile> {
  protected override navigateUrl = '/load-profiles';

  public faCheck = faCheck;

  constructor(protected service: LoadProfileService) {
    super(service);
  }

  public override add() {
    this.router.navigate(['/load-profile/new']);
  }
}
