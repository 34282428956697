
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormGroupAccessorConnector } from '@clean-code/shared/components/ui-form-controls';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TranslocoModule } from '@jsverse/transloco';
import { DateRangeInputForm } from '../date-range-input-form';

@Component({
  selector: 'ui-date-range',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FontAwesomeModule,
    TranslocoModule,
    UtilDateModule
],
  templateUrl: './shared-components-ui-date-range.component.html',
})
export class SharedComponentsUiDateRangeComponent extends FormGroupAccessorConnector<DateRangeInputForm> {
  public override formGroup = new DateRangeInputForm();
  public faTimes = faTimes;

  public clearStartDate() {
    this.formGroup.reset();
  }
}
