<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>

<div mat-dialog-title class="!flex">
  <h2 class="dialog-title">{{ this.data.name }}</h2>

  <fa-icon
    class="cursor-pointer"
    [icon]="faXmarkLarge"
    mat-dialog-close
  ></fa-icon>
</div>

<mat-dialog-content>
  <market-details
    [dataSource]="data.dataSource.settings"
    [hideClosingValueHeader]="data.hideClosingValueHeader"
  ></market-details>
</mat-dialog-content>
