
import {
  Component,
  ComponentRef,
  EventEmitter,
  Output,
  effect,
  inject,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  IDashboardWidgeConfig,
  IWidgetTypeConfigComponent,
  WidgetTypeProvider,
} from '@clean-code/shared/components/ui-dashboard';
import { LoadingIndicatorComponent } from '@clean-code/shared/components/ui-progress-bar';
import { DashboardWidgetFacade } from '@clean-code/shared/dashboard/widget/domain';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { TranslocoModule } from '@jsverse/transloco';
import { DynamicModule } from 'ng-dynamic-component';

@Component({
  standalone: true,
  selector: 'dashboard-widget-type-config',
  templateUrl: './widget-type-config.component.html',
  styleUrls: ['./widget-type-config.component.scss'],
  imports: [
    DynamicModule,
    TranslocoModule,
    LoadingIndicatorComponent,
    UtilDateModule
],
})
export class WidgetTypeConfigComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() changeCallback = new EventEmitter<IDashboardWidgeConfig>();

  public component: { new (): IWidgetTypeConfigComponent };
  public form: UntypedFormGroup;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public inputs: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public outputs: any;
  private widgetFacade = inject(DashboardWidgetFacade);

  public type = this.widgetFacade.type;
  public config = this.widgetFacade.config;
  public isLoading = true;

  constructor(private widgetTypeProvider: WidgetTypeProvider) {
    effect(() => {
      const type = this.type();
      if (type) {
        const provider = this.widgetTypeProvider.getProvider(type);
        this.component = provider ? provider.configCmp : undefined;
      }

      this.isLoading = false;
    });
  }

  public componentCreated(compRef: ComponentRef<IWidgetTypeConfigComponent>) {
    this.form = compRef.instance.form;
  }
}
