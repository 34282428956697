import { Directive, OnDestroy, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  IDashboardWidgetTypeConfig,
  IWidgetTypeConfigComponent,
} from '@clean-code/shared/components/ui-dashboard';
import { DashboardWidgetFacade } from '@clean-code/shared/dashboard/widget/domain';
import { Subject } from 'rxjs';

@Directive({})
export class BaseWidgetTypeConfigComponent<T extends IDashboardWidgetTypeConfig>
  implements IWidgetTypeConfigComponent, OnDestroy
{
  public form!: UntypedFormGroup;
  protected closeSubject = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private widgetFacade = inject(DashboardWidgetFacade);
  public config = this.widgetFacade.config;

  ngOnDestroy(): void {
    this.closeSubject.next();
    this.closeSubject.complete();
  }
}
