<div class="widget-type-config-component" class="{{ type() }}">
  @if (component) {
    <div>
      <ndc-dynamic
        #dynamic
        [ndcDynamicComponent]="component"
        [ndcDynamicInputs]="inputs"
        [ndcDynamicOutputs]="outputs"
        (ndcDynamicCreated)="componentCreated($event)"
        >
      </ndc-dynamic>
    </div>
  }

  @if (isLoading) {
    <div>
      <cc-loading-indicator></cc-loading-indicator>
    </div>
  }

  @if (!component) {
    <div>
      <span class="unknown">{{
        'DASHBOARDWIDGET.MESSAGES.TYPE.UNKNOWN' | transloco
      }}</span>
    </div>
  }
</div>
