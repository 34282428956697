import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SummaryType } from '@backoffice-frontend/time-series-identifier/domain';

export class WidgetHelper {
  public static createDataSourceForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      identifier: new UntypedFormControl(null, [Validators.required]),
      summaryType: new UntypedFormControl('DEFAULT' as SummaryType, [
        Validators.required,
      ]),
      additionalDataSources: new UntypedFormArray([]),
      aroon: new UntypedFormGroup({
        enabled: new UntypedFormControl(false, [Validators.required]),
        period: new UntypedFormControl(25, [Validators.required]),
      }),
      sma: new UntypedFormGroup({
        enabled: new UntypedFormControl(false, [Validators.required]),
        periode: new UntypedFormControl(new Array<number>()),
      }),
      bollinger: new UntypedFormGroup({
        enabled: new UntypedFormControl(false, [Validators.required]),
        period: new UntypedFormControl(20, [Validators.required]),
        standardDeviation: new UntypedFormControl(2, [Validators.required]),
      }),
    });
  }
}
