import { inject, Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import {
  IDashboardWidgetConfigDto,
  IDashboardWidgetDetailDto,
  IDashboardWidgetDto,
  IDashboardWidgetReferenceDto
} from '@clean-code/shared/components/ui-dashboard';
import { Observable } from 'rxjs';

import { WidgetStore } from '../+state/dashboard-widget.store';
import { DashboardWidgetDataService } from '../infrastructure/dashboard-widget-data.service';

@Injectable()
export class DashboardWidgetFacade {
  readonly store = inject(WidgetStore);

  public widget = this.store.widget;
  public inputs = this.store.inputs;
  public hasUserPermission = this.store.hasUserPermission;
  public editMode = this.store.editMode;
  public title = this.store.title;
  public config = this.store.config;
  public type = this.store.type;

  constructor(private service: DashboardWidgetDataService) {
  }

  add$(input: IDashboardWidgetDetailDto) {
    return this.service.add$(input);
  }

  reference$(input: IDashboardWidgetReferenceDto) {
    return this.service.reference$(input);
  }

  updateWidget(input: IDashboardWidgetDetailDto) {
    return this.store.loadWidgetWidget(input);
  }

  updateWidgetWithSettings(
    input: IDashboardWidgetDetailDto,
    settings: IDashboardWidgetConfigDto
  ) {
    return this.store.updateWidgetWithSettings(input, settings);
  }

  update$(input: IDashboardWidgetDetailDto) {
    return this.service.update$(input);
  }

  updateConfig$(input: IDashboardWidgetConfigDto) {
    return this.store.updateWidgetSettings(input);
  }

  getAssignable$(dashboardId: ID): Observable<IDashboardWidgetDto[]> {
    return this.service.getAssignable$(dashboardId);
  }

  getAvailableTypes$(): Observable<string[]> {
    return this.service.getAvailableTypes$();
  }

  delete$(id: ID) {
    return this.service.delete$(id);
  }

  remove$(o: IDashboardWidgetDetailDto) {
    return this.service.remove$(o);
  }

  dashboardWidgetGetByDashboardId$(id: ID): Observable<IDashboardWidgetDto[]> {
    return this.service.dashboardWidgetGetByDashboardId$(id);
  }

  public loadWidget(widget: IDashboardWidgetDetailDto) {
    this.store.loadWidgetWidget(widget);
  }

  updateTitle(value: string) {
    this.store.updateTitle(value);
  }

  updateEditWidget() {
    this.store.updateEditWidget();
  }

  updateWidgetType(type: string) {
    this.store.updateWidgetType(type);
  }
}
