import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SplashScreenService } from '@clean-code/shared/common';
import { SideNavService } from '@clean-code/shared/templates/ui-tailwind-full-width';
import { SideNavComponent } from '../app-side-nav/side-nav.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  standalone: true,
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  imports: [RouterModule, SideNavComponent, UserMenuComponent],
  providers: [SideNavService],
})
export class AdminLayoutComponent implements OnInit {
  public readonly service = inject(SideNavService);
  private splashState = inject(SplashScreenService);

  ngOnInit(): void {
    this.splashState.hideState();
  }

  public toggleUserMenu() {
    this.service.toggleUserMenu();
  }
}
