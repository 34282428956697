<div class="h-full overflow-y-auto">
  <mat-horizontal-stepper #stepper [linear]="true" class="h-full">
    <mat-step [stepControl]="baseChild?.form">
      <ng-template matStepLabel>{{
        'DASHBOARDWIDGET.DIALOG-TITLE.CREATE-BASE' | transloco
      }}</ng-template>

      <ng-template matStepContent>
        <dashboard-base-widget-base-config
          #baseChild
          [widgetType]="widgetType"
        ></dashboard-base-widget-base-config>
      </ng-template>
    </mat-step>
    <mat-step [stepControl]="configChild?.form">
      <ng-template matStepLabel>{{
        'DASHBOARDWIDGET.DIALOG-TITLE.CREATE-CONFIG' | transloco
      }}</ng-template>

      <ng-template matStepContent>
        <dashboard-widget-type-config #configChild>
        </dashboard-widget-type-config>
      </ng-template>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div
  class="flex flex-row justify-between items-center"
  *ngVar="stepper.selectedIndex; let index"
  >
  <div class="flex flex-row space-x-1 my-1">
    <button
      mat-mini-fab
      class="mat-mini-fab-small"
      (click)="stepper.previous()"
      [disabled]="index <= 0 || baseChild?.form?.invalid"
    ></button>
    <button
      mat-mini-fab
      class="mat-mini-fab-small"
      [disabled]="index > 0"
      (click)="stepper.next()"
      >
      >
    </button>
  </div>
  @if (index > 0) {
    <div class="flex flex-row space-x-1">
      <cc-cancel-action-button (onClick)="cancel()"></cc-cancel-action-button>
      <cc-save-action-button
        [disabled]="baseChild?.form?.invalid || configChild?.form?.invalid"
        (onClick)="save()"
      ></cc-save-action-button>
    </div>
  }
</div>
