import { computed, inject } from '@angular/core';
import { AuthStore } from '@clean-code/shared/auth/util-auth';
import {
  IDashboardWidgetConfigDto,
  IDashboardWidgetDetailDto,
  IWidgetTypeContentComponent,
} from '@clean-code/shared/components/ui-dashboard';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { debounceTime, filter, pipe, switchMap, tap } from 'rxjs';
import { DashboardWidgetDataService } from '../infrastructure/dashboard-widget-data.service';

type WidgetState = {
  editMode: boolean;
  isLoading: boolean;
  widget: IDashboardWidgetDetailDto;
  title: string;

  component: { new (): IWidgetTypeContentComponent } | undefined;
  config: any;
  type: string;
};

const initialState: WidgetState = {
  editMode: false,
  widget: null,
  isLoading: false,
  title: null,
  component: undefined,
  config: null,
  type: null,
};

export const WidgetStore = signalStore(
  withState(() => initialState),
  withComputed(({ widget, config }) => {
    const auth = inject(AuthStore);

    return {
      hasUserPermission: computed(() => {
        return widget().creator?.id === auth.id();
      }),

      inputs: computed(() => {
        return { widget: widget(), config: config() };
      }),
    };
  }),
  withMethods((store) => {
    const service = inject(DashboardWidgetDataService);

    function updateWidgetWithSettings(
      input: IDashboardWidgetDetailDto,
      config: IDashboardWidgetConfigDto,
    ) {
      const widget = {
        ...store.widget(),
        name: input.name,
        description: input.description?.localizations.find((x) => x.value)
          ? input.description
          : null,
        type: input.type,
        shared: input.shared,
        visibleTo: input.visibleTo,
        config: JSON.stringify(config),
      };

      patchState(store, { widget });
      patchState(store, { config });
    }

    return {
      async loadWidgetWidget(widget: IDashboardWidgetDetailDto) {
        patchState(store, {
          widget: {
            ...store.widget(),
            ...widget,
          },
        });

        if (widget.config) {
          const config = JSON.parse(widget.config);
          patchState(store, { config });
        }
      },

      async loadWidgetComponent(
        component: { new (): IWidgetTypeContentComponent } | undefined,
      ) {
        patchState(store, { component });
      },

      async updateTitle(title: string) {
        patchState(store, { title });
      },

      updateEditWidget(): void {
        this.loadEditWidget();
      },

      updateEditMode(editMode: boolean): void {
        patchState(store, { editMode });
      },

      updateWidgetType(type: string): void {
        patchState(store, { type });
      },

      loadEditWidget: rxMethod<void>(
        pipe(
          debounceTime(300),
          tap(() => patchState(store, { isLoading: true })),
          filter(() => !!store.widget().id),
          switchMap(() =>
            service.getById$(store.widget().id).pipe(
              tapResponse({
                next: (widget: IDashboardWidgetDetailDto) => {
                  const newWidget = {
                    ...store.widget(),
                    name: widget.name,
                    description: widget.description,
                  };
                  patchState(store, { widget: newWidget });
                  patchState(store, { type: newWidget.type });
                },
                error: console.error,
                finalize: () => patchState(store, { isLoading: false }),
              }),
            ),
          ),
        ),
      ),

      updateWidgetWithSettings(
        input: IDashboardWidgetDetailDto,
        config: IDashboardWidgetConfigDto,
      ) {
        updateWidgetWithSettings(input, config);
      },

      updateWidgetSettings: rxMethod<IDashboardWidgetConfigDto>(
        pipe(
          debounceTime(300),
          switchMap((config) => {
            const newConfig = {
              ...config,
              config: JSON.stringify(config.config),
            };
            return service.updateConfig$(newConfig).pipe(
              tapResponse({
                next: () => {
                  const newObject = {
                    ...store.widget(),
                    config: config.config,
                  };

                  updateWidgetWithSettings(newObject, config.config);
                },
                error: console.error,
                finalize: () => patchState(store, { isLoading: false }),
              }),
            );
          }),
        ),
      ),
    };
  }),
);
