
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { VarDirective } from '@backoffice-frontend/shared/directives/common';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import {
  IDashboardWidgetDetailDto,
  IWidgetTypeProvider,
} from '@clean-code/shared/components/ui-dashboard';
import { DashboardWidgetFacade } from '@clean-code/shared/dashboard/widget/domain';
import { WidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/ui-widget';
import { ToastService } from '@clean-code/shared/util/util-toast';
import { TranslocoModule } from '@jsverse/transloco';
import { Observable } from 'rxjs';
import { BaseWidgetBaseConfigComponent } from './../widget-base-edit/widget-base-edit.component';
@Component({
  standalone: true,
  selector: 'dashboard-widget-edit',
  templateUrl: './widget-edit.component.html',
  styleUrls: ['./widget-edit.component.scss'],
  imports: [
    MatStepperModule,
    MatButtonModule,
    MatIconModule,
    TranslocoModule,
    VarDirective,
    WidgetTypeConfigComponent,
    BaseWidgetBaseConfigComponent,
    ActionButtonModule
],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DashboardWidgetEditComponent {
  @HostBinding('class') class = 'dashboard-widget-edit';

  @ViewChild('stepper') public stepper: MatStepper;
  @ViewChild('baseChild', { static: false })
  public baseChild: BaseWidgetBaseConfigComponent;
  @ViewChild('configChild', { static: false })
  public configChild: WidgetTypeConfigComponent;
  @Output() public callback = new EventEmitter();

  public widgetType: IWidgetTypeProvider;
  public dashboardWidgetFacade = inject(DashboardWidgetFacade);

  constructor(
    public dialogRef: MatDialogRef<DashboardWidgetEditComponent>,
    private toastService: ToastService
  ) {
    this.dashboardWidgetFacade.updateEditWidget();
  }

  public save() {
    this.dashboardWidgetFacade.updateWidgetWithSettings(
      this.baseChild.form.value,
      this.configChild.form.value
    );

    let request: Observable<IDashboardWidgetDetailDto>;
    if (this.dashboardWidgetFacade.widget().id) {
      request = this.dashboardWidgetFacade.update$(
        this.dashboardWidgetFacade.widget()
      );
    } else {
      request = this.dashboardWidgetFacade.add$(
        this.dashboardWidgetFacade.widget()
      );
    }

    request.subscribe((result) => {
      if (result) {
        this.toastService.showSuccess(
          'DASHBOARDWIDGET.MESSAGES.CREATE.SUCCESS'
        );

        this.dashboardWidgetFacade.updateTitle(
          this.dashboardWidgetFacade.widget().name?.localizations[0].value
        );

        this.callback.next(undefined);
      }
    });
  }

  public cancel() {
    this.callback.next(undefined);
  }
}
