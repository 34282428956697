
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { EnumService } from '@backoffice-frontend/shared/bo/util-masterdata';
import { scopeLoader } from '@backoffice-frontend/shared/util-transloco';
import { BaseFormulaTypeConfigComponent } from '@clean-code/feature/formula-builder/domain';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';

export enum MAMethodeComponentTypes {
  MethodeType = 'SMAFormulaMethod',
}

@Component({
  standalone: true,
  templateUrl: './Ma-method.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TranslocoModule,
    UtilDateModule,
],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'ui-formula-methods', //snake-casing is changed from transloco when using selectTranslate with scope
        alias: 'ui-formula-methods',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../${root}/${lang}.json`)
        ),
      },
    },
  ],
})
export class MAMethodeComponent extends BaseFormulaTypeConfigComponent {
  private enumService = inject(EnumService);

  public timeFrameTypes$ = this.enumService.getEnumType$('TimeFrameType');

  formGroup = new FormGroup({
    averagePeriod: new FormControl<number>(null),
  });
}
