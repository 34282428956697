import * as Sentry from '@sentry/angular-ivy';

export interface SentryConfig {
  dsn: string;
  environment: string;
  tracesSampleRate: number;
  tracePropagationTargets: string[];
  replaysSessionSampleRate: number;
  replaysOnErrorSampleRate: number;
  networkDetailAllowUrls: string[];
  ignoreErrors: string[];
}

export class SentryHelper {
  public static init(settings: SentryConfig): void {
    Sentry.init({
      dsn: settings.dsn,
      environment: settings.environment,
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        // Registers the Replay integration,
        // which automatically captures Session Replays
        // new Sentry.Replay({
        //   maskAllText: false,
        //   blockAllMedia: false,
        //   networkDetailAllowUrls: settings.networkDetailAllowUrls,
        //   networkRequestHeaders: ['X-Custom-Header'],
        //   networkResponseHeaders: ['X-Custom-Header'],
        // }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: settings.tracesSampleRate,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: settings.tracePropagationTargets,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: settings.replaysSessionSampleRate,
      replaysOnErrorSampleRate: settings.replaysOnErrorSampleRate,
    });
  }
}
