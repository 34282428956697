import { CompanyResolver } from '@backoffice/contacts/util-api';
import { LoadProfileResolver } from '@backoffice/load-profile/util-api';
import { EnergySourceResolver } from '@clean-code/shared/bo/util-common';

export const loadProfileRoute = {
  path: 'load-profile',

  children: [
    {
      path: ':id',
      loadComponent: () =>
        import('./load-profile.component').then(
          (mod) => mod.LoadProfileComponent
        ),
      data: {
        translate: 'load-profile',
      },
      resolve: {
        entity: LoadProfileResolver,
      },
    },
    {
      path: ':id/customer/:companyId/:energySource',
      loadComponent: () =>
        import('./load-profile.component').then(
          (mod) => mod.LoadProfileComponent
        ),
      data: {
        translate: 'load-profile',
      },
      resolve: {
        entity: LoadProfileResolver,
        energySource: EnergySourceResolver,
        company: CompanyResolver,
      },
    },
  ],
};
