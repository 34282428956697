@if (widget(); as widget) {
  <h2 mat-dialog-title>
    {{ widget?.name.localizations[0].value }}
    {{ 'DASHBOARDWIDGET.CONFIG' | transloco }}
  </h2>
  @if (component$ | async; as component) {
    <mat-dialog-content>
      <div class="{{ widget?.type }}">
        <ndc-dynamic
          [ndcDynamicComponent]="component"
          [ndcDynamicInputs]="inputs$ | async"
          [ndcDynamicOutputs]="outputs"
          (ndcDynamicCreated)="componentCreated($event)"
        >
        </ndc-dynamic>
      </div>
      @if (!component) {
        <div>
          <span class="loading">{{ 'common.LOADING' | transloco }}</span>
        </div>
      }
      @if (!component) {
        <div>
            <span class="unknown">{{
                'DASHBOARDWIDGET.MESSAGES.TYPE.UNKNOWN' | transloco
              }}</span>
        </div>
      }
    </mat-dialog-content>
  }
  <mat-dialog-actions align="end">
    <cc-cancel-action-button (onClick)="close()"></cc-cancel-action-button>
    <cc-save-action-button (onClick)="save()"></cc-save-action-button>
  </mat-dialog-actions>
}
