import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterService } from '@clean-code/shared/common';

@Injectable({
  providedIn: 'root',
})
export class ForecastNotImportedGuard {
  private routerService = inject(RouterService);

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return !this.routerService.getLastRouteChildData()?.entity?.isImported;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ForecastImportedGuard {
  private routerService = inject(RouterService);

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.routerService.getLastRouteChildData()?.entity?.isImported;
  }
}
