<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>
@if (data$ | async; as data) {
  <div class="content h-full overflow-auto">
    <table class="table-layout">
      <thead>
        <tr>
          <th scope="col">{{ 'master-data.COMPANY' | transloco }}</th>
          <th scope="col">{{ 'master-data.VOLUME' | transloco }}</th>
          <th scope="col">{{ 'master-data.OPEN' | transloco }}</th>
          <th scope="col">{{ 'master-data.CLOSED' | transloco }}</th>
        </tr>
      </thead>
      <tbody class="overflow-y-scroll">
        <!-- Odd row -->
        @for (element of data; track element) {
          <tr
            class="border-b"
            global-tooltip="{{ (element.value.volume | number : '.2-2') + ' GWh' }}"
            >
            @if (config?.filter.energySource; as energySource) {
              <td
                class="hover:text-gray-500 cursor-pointer"
          [routerLink]="[
            '/company-' +
              energySource.toLowerCase() +
              '/' +
              element.value.companyId +
              '/' +
              energySource.toLowerCase() +
              '/contract'
          ]"
                >
                {{ element.key }}
              </td>
            }
            <td class="text-right">
              {{ element.value.volume | number : '1.0-0' }} GWh
            </td>
            <td class="text-right">
              {{ element.value.open | number : '1.0-0' }} GWh
            </td>
            <td class="text-right">
              {{ element.value.closed | number : '1.0-0' }} GWh
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
}
