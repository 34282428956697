import { Route } from '@angular/router';
import { LoadProfileListComponent } from './load-profile-list.component';

export const loadProfilesRoute: Route = {
  path: 'load-profiles',
  component: LoadProfileListComponent,
  children: [
    {
      path: 'preview/:id',
      loadComponent: () =>
        import('@backoffice/load-profile/feature-preview').then(
          (mod) => mod.LoadProfilePreviewComponent
        ),
    },
  ],
};
