import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { indicate } from '@clean-code/shared/common';
import { BaseWidgetTypeContentComponent } from '@clean-code/shared/components/ui-dashboard';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { MarketHknDistributionService } from '../services/market-hkn-distribution.service';
import { MarketHknDistributionWidgetTypeConfig } from './../market-hkn-distribution';

@Component({
  standalone: true,
  selector: 'dashboard-market-hkn-distribution-content',
  templateUrl: './market-hkn-distribution-content.component.html',
  styleUrls: ['./market-hkn-distribution-content.component.css'],
  imports: [CommonModule, ProgressBarComponent, DxPieChartModule],
})
export class MarketHknDistributionContentComponent extends BaseWidgetTypeContentComponent<MarketHknDistributionWidgetTypeConfig> {
  public isLoading$ = new BehaviorSubject<boolean>(false);

  private service = inject(MarketHknDistributionService);

  public data$ = this.configObservable.pipe(
    filter((value: MarketHknDistributionWidgetTypeConfig) => !!value.filter),
    switchMap((value: MarketHknDistributionWidgetTypeConfig) =>
      this.service
        .dashboardClosedHKNByType$(value.filter)
        .pipe(indicate(this.isLoading$))
    )
  );
}
