<mat-accordion>
  <mat-expansion-panel
    *ngxPermissionsOnly="contactsRootNode"
    routerLinkActive="active-panel"
    [routerLinkActiveOptions]="{ exact: false }"
    [expanded]="rlac.isActive"
    #rlac="routerLinkActive"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon class="sidebar-icon" [icon]="faIndustry"></fa-icon>
        {{ 'sidebar.customers.NAME' | transloco }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.companySideNavPermisssions"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/companies']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.customers.subitems.CUSTOMERS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="
          contactsPermisssion.customerUserSideNavPermisssions
        "
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/persons']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.customers.subitems.PERSONS' | transloco }}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngxPermissionsOnly="loadProfileRoot"
    routerLinkActive="active-panel"
    [routerLinkActiveOptions]="{ exact: false }"
    [expanded]="rlac.isActive"
    #rlac="routerLinkActive"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon class="sidebar-icon" [icon]="faChartBar"></fa-icon>
        {{ 'sidebar.loadprofiles.NAME' | transloco }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="loadProfilePermisssion.loadProfileTabPermisssion"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/load-profiles']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.loadprofiles.subitems.LOADPROFILES' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="
          standardizedLoadProfile.standardizedLoadProfileTabPermisssion
        "
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/standardized-load-profiles']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.loadprofiles.subitems.NORM_LOADPROFILES' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="
          loadProfilePermisssion.loadProfileLimitTabPermisssion
        "
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/load-profile-limits']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.loadprofiles.subitems.LOADPROFILE_LIMITS' | transloco }}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngxPermissionsOnly="forecastPermissions.forecastRootSideNavPermisssion"
    routerLinkActive="active-panel"
    [routerLinkActiveOptions]="{ exact: false }"
    [expanded]="rlac.isActive"
    #rlac="routerLinkActive"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon class="sidebar-icon" [icon]="faChartLine"></fa-icon>
        {{ 'sidebar.forecasts.NAME' | transloco }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="forecastPermissions.forecastSideNavPermisssion"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/forecasts']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.forecasts.subitems.FORECASTS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="forecastPermissions.editForecastSettings"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/forecast-settings']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.forecasts.subitems.FORECAST_SETTINGS' | transloco }}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngxPermissionsOnly="costingRoot"
    routerLinkActive="active-panel"
    [routerLinkActiveOptions]="{ exact: false }"
    [expanded]="rlac.isActive"
    #rlac="routerLinkActive"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon class="sidebar-icon" [icon]="faEuroSign"></fa-icon>
        {{ 'sidebar.costings.NAME' | transloco }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="forwardPriceCurvePermissions.manage"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/costing/forward-price-curves']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.costings.subitems.FORWARD_CURVE' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="surchargePermissions.admin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/costing/surcharges']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.costings.subitems.SURCHARGES' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="surchargePermissions.admin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/costing/products']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.costings.subitems.PRODUCTS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="offerPermissions.powerSideBarPermisssion"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/costing/offer-power']"
        >
          <div class="active-bar"></div>
          {{ 'costing.offer-power.TITLE_PLURAL' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="offerPermissions.gasSideBarPermisssion"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/costing/offer-gas']"
        >
          <div class="active-bar"></div>
          {{ 'costing.offer-gas.TITLE_PLURAL' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="offerPermissions.offerPowerHknTypes"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/costing/hkn-types']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.costings.subitems.HKN_TYPES' | transloco }}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngxPermissionsOnly="contractRoot"
    routerLinkActive="active-panel"
    [routerLinkActiveOptions]="{ exact: false }"
    [expanded]="rlac.isActive"
    #rlac="routerLinkActive"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon class="sidebar-icon" [icon]="faFileContract"></fa-icon>
        {{ 'contracts.PLURAL' | transloco }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contractsPermissions.contractsPowerContract"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/contracts/power']"
        >
          <div class="active-bar"></div>
          {{ 'contracts.power.PLURAL' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contractsPermissions.contractsGasContract"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/contracts/gas']"
        >
          <div class="active-bar"></div>
          {{ 'contracts.gas.PLURAL' | transloco }}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngxPermissionsOnly="energyOrderRootPermissions"
    routerLinkActive="active-panel"
    [routerLinkActiveOptions]="{ exact: false }"
    [expanded]="rlac.isActive"
    #rlac="routerLinkActive"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon class="sidebar-icon" [icon]="faCalculator"></fa-icon>
        {{ 'sidebar.pfm-trading.NAME' | transloco }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="energyOrderPowerPermissions.sideBarPermisssion"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/energy-order/pfm-trading-power']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.pfm-trading.subitems.PFM_POWER' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="energyOrderGasPermissions.sideBarPermisssion"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/energy-order/pfm-trading-gas']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.pfm-trading.subitems.PFM_GAS' | transloco }}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngxPermissionsOnly="limitReportsSideNavPermissions"
    routerLinkActive="active-panel"
    [routerLinkActiveOptions]="{ exact: false }"
    [expanded]="rlac.isActive"
    #rlac="routerLinkActive"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon class="sidebar-icon" [icon]="faFileAlt"></fa-icon>
        {{ 'limit-reports.TITLE_PLURAL' | transloco }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[
          limitReportPermissions.limitReportPowerAdmin,
          limitReportPermissions.limitReportPowerEditor
        ]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/limit-reports/power']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.limit-reports.POWER' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[
          limitReportPermissions.limitReportGasAdmin,
          limitReportPermissions.limitReportGasEditor
        ]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/limit-reports/gas']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.limit-reports.GAS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[
          limitReportPermissions.limitReportOtherAdmin,
          limitReportPermissions.limitReportOtherEditor
        ]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/limit-reports/other']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.limit-reports.OTHER' | transloco }}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngxPermissionsOnly="customerPoolsSideNavPermissions"
    routerLinkActive="active-panel"
    [routerLinkActiveOptions]="{ exact: false }"
    [expanded]="rlac.isActive"
    #rlac="routerLinkActive"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon class="sidebar-icon" [icon]="faUsers"></fa-icon>
        {{ 'customer-pools.TITLE_PLURAL' | transloco }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[
          customerPoolPermissions.customerPoolPowerAdmin,
          customerPoolPermissions.customerPoolPowerPoolManager
        ]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/customer-pools/power/']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.limit-reports.POWER' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[
          customerPoolPermissions.customerPoolGasAdmin,
          customerPoolPermissions.customerPoolGasPoolManager
        ]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/customer-pools/gas/']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.limit-reports.GAS' | transloco }}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngxPermissionsOnly="[
      limitReportPermissions.limitReportLegacySignalMonitoring
    ]"
    routerLinkActive="active-panel"
    [routerLinkActiveOptions]="{ exact: false }"
    [expanded]="rlac.isActive"
    #rlac="routerLinkActive"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon class="sidebar-icon" [icon]="faFileAlt"></fa-icon>
        {{ 'sidebar.limit-reports.subitems.monitoring.MONITORING' | transloco }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[
          limitReportPermissions.limitReportLegacySignalMonitoring
        ]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="[
            '/limit-reports/monitoring/limit-report-monitoring-legacy-signals'
          ]"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.limit-reports.subitems.monitoring.SIGNALS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[userLimitPermissions.userLimitAccess]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/limits']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.monitoring.USER_LIMIT_PLURAL' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[monitoringPermissions.healthCheckAccess]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/healthcheck']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.monitoring.HEALTH_CHECK' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[monitoringPermissions.limitReportPowerSignalMail]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/monitoring/report-power-signals']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.monitoring.LIMIT_REPORT_POWER_SIGNALS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[monitoringPermissions.limitReportGasSignalMail]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/monitoring/report-gas-signals']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.monitoring.LIMIT_REPORT_GAS_SIGNALS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[monitoringPermissions.limitReportOtherSignalMail]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/monitoring/report-other-signals']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.monitoring.LIMIT_REPORT_OTHER_SIGNALS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[
          monitoringPermissions.customerPoolPowerSignalMail
        ]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/monitoring/pool-power-signals']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.monitoring.CUSTOMER_POOL_POWER_SIGNALS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[
          monitoringPermissions.customerPoolPowerSpreadSignalMail
        ]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/monitoring/pool-power-spread-signals']"
        >
          <div class="active-bar"></div>
          {{
            'sidebar.monitoring.CUSTOMER_POOL_POWER_SPREAD_SIGNALS' | transloco
          }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[monitoringPermissions.customerPoolGasSignalMail]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/monitoring/pool-gas-signals']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.monitoring.CUSTOMER_POOL_GAS_SIGNALS' | transloco }}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
    routerLinkActive="active-panel"
    [routerLinkActiveOptions]="{ exact: false }"
    [expanded]="rlac.isActive"
    #rlac="routerLinkActive"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon class="sidebar-icon" [icon]="faGamepadModern"></fa-icon>
        Kontroling
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <!-- TODO: Add permissions -->
      <li class="sidebar-buttom">
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/controlling']"
        >
          <div class="active-bar"></div>
          Table
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
    routerLinkActive="active-panel"
    [routerLinkActiveOptions]="{ exact: false }"
    [expanded]="rlac.isActive"
    #rlac="routerLinkActive"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon class="sidebar-icon" [icon]="faDesktopAlt"></fa-icon>
        {{ 'sidebar.MARKT_APP' | transloco }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/news']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.NEWS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/termin']"
        >
          <div class="active-bar"></div>
          {{ 'termin.PLURAL' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/analysis']"
        >
          <div class="active-bar"></div>
          {{ 'analysis.PLURAL' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/wikis']"
        >
          <div class="active-bar"></div>
          {{ 'wiki.PLURAL' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/market/energy-market']"
        >
          <div class="active-bar"></div>
          {{ 'side-nav.ENERGY_MARKET' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/market/market']"
        >
          <div class="active-bar"></div>
          {{ 'side-nav.FINANCIAL_MARKETS' | transloco }}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngxPermissionsOnly="settingsSideNavPermisssions"
    routerLinkActive="active-panel"
    [routerLinkActiveOptions]="{ exact: false }"
    [expanded]="rlac.isActive"
    #rlac="routerLinkActive"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon class="sidebar-icon" [icon]="faCogs"></fa-icon>
        {{ 'sidebar.settings.NAME' | transloco }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="permissions.manageUsersPermission"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/internal-users']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.USER' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="permissions.manageUsersPermission"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/user-company-settings']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.USER_COMPANY_SETTINGS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="permissions.manageRolesPermission"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/roles/backoffice']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.BACKOFFICE_ROLES' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="permissions.manageRolesPermission"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/roles/frontoffice']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.FRONTOFFICE_ROLES' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="permissions.manageRolesPermission"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/roles/frontoffice-int']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.FRONTOFFICE_INT_ROLES' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/companytypes']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.CUSTOMER_SEGMENTS' | transloco }}
        </a>
      </li>
      <!-- <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[
          contactsPermisssion.settingPowerUser,
          contactsPermisssion.settingAdmin
        ]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/emails-distributor']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.EMAIL_DISTRIBUTOR' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="[
          contactsPermisssion.settingPowerUser,
          contactsPermisssion.settingAdmin
        ]"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['settings/email-templates']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.EMAIL_TEMPLATES' | transloco }}
        </a>
      </li> -->
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/marketAreas']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.MARKET_AREAS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/deliveryAreas']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.DELIVERY_AREAS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['settings/trading-calendars']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.TRADING_CALENDARS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/hkn-identifier']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.HKN_IDENTIFIERS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/norm-cubic-meters']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.NORM_CUBIC_METER' | transloco }}
        </a>
      </li>

      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="settingPermissions.settingSpot"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/spotConfigurations']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.SPOT_CONFIGURAITONS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/time-series-identifiers']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.TIME_SERIES_IDENTIFIER' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/time-series-analysis-identifier']"
        >
          <div class="active-bar"></div>
          {{
            'sidebar.settings.subitems.TIME_SERIES_ANALYSIS_IDENTIFIER'
              | transloco
          }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/formulas']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.FORMULAS' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/energy-source']"
        >
          <div class="active-bar"></div>
          {{ 'sidebar.settings.subitems.ENERGY_SOURCES' | transloco }}
        </a>
      </li>
      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/time-series-identifier-parser']"
        >
          <div class="active-bar"></div>
          {{ 'time-series-identifier.parser.PLURAL' | transloco }}
        </a>
      </li>

      <li
        class="sidebar-buttom"
        *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
      >
        <a
          routerLinkActive="active-link"
          class="sidebar-option"
          [routerLink]="['/settings/app-settings']"
        >
          <div class="active-bar"></div>
          {{ 'settings.PLURAL' | transloco }}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>
  <span
    dashboard-menu
    *ngxPermissionsOnly="contactsPermisssion.settingAdmin"
  ></span>
</mat-accordion>
