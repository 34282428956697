import { CommonModule } from '@angular/common';
import {
  Component,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  Message,
  Notification,
  NotificationHostDirective,
  NotificationService,
} from '@clean-code/notifications/domain';
import { faEnvelopeOpen } from '@fortawesome/pro-solid-svg-icons';
import { TranslocoModule } from '@jsverse/transloco';
import { NotificationItemComponent } from '../notification-item/notification-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  standalone: true,
  selector: 'notifications-notification-host',
  templateUrl: './notification-host.component.html',
  styleUrls: ['./notification-host.component.scss'],
  imports: [
    CommonModule,

    TranslocoModule,
    FontAwesomeModule,

    NotificationItemComponent,
    NotificationHostDirective,
  ],
})
export class NotificationHostComponent implements OnInit {
  @Input()
  public notification: Message;

  @ViewChild(NotificationHostDirective, { static: true })
  public notificationHost: NotificationHostDirective;

  public componentRef: ComponentRef<Notification>;

  public faEnvelopeOpen = faEnvelopeOpen;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private notificationSerivce: NotificationService,
  ) {}

  public ngOnInit() {
    this.loadComponent();
  }

  private loadComponent(): void {
    const notificationType = this.notificationSerivce.getNotificationType(
      this.notification.type,
    );
    if (!notificationType) {
      return;
    }

    const viewContainerRef = this.notificationHost.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = this.viewContainerRef.createComponent(notificationType);
    this.componentRef.instance.message = this.notification;
  }

  public notificationClicked($event: Event) {
    this.componentRef.instance.itemClicked($event);
  }

  public markMessageAsRead($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();

    if (!this.notification.isRead) {
      this.notificationSerivce.setNotificationRead(this.notification.id);
    }
  }
}
