<!-- <div id="pivotgrid-demo"> -->
<!-- <dx-chart id="sales-chart">
    <dxo-common-series-settings type="bar"></dxo-common-series-settings>
    <dxo-size [height]="200"></dxo-size>
    <dxo-adaptive-layout [width]="450"></dxo-adaptive-layout>
    <dxo-tooltip
      [enabled]="true"
      format="currency"
      [customizeTooltip]="customizeTooltip"
    ></dxo-tooltip>
  </dx-chart> -->

<dx-pivot-grid
  id="pivotgrid"
  [allowExpandAll]="true"
  [allowSorting]="false"
  [allowSortingBySummary]="false"
  [showBorders]="true"
  [showColumnGrandTotals]="false"
  [showRowGrandTotals]="false"
  [showRowTotals]="false"
  [showColumnTotals]="false"
  [dataSource]="dataSource$ | async"
  [allowFiltering]="false"
  [fieldChooser]="null"
  (onCellPrepared)="onCellPrepared($event)"
  width="auto"
>
  <dxo-scrolling mode="virtual"></dxo-scrolling>
</dx-pivot-grid>
<!-- </div> -->
